/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Index APP (configura o Container)
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  	mainContainer: {
  		bottom: 0,
   	flex: 1,
    	height: "auto",
    	width: "100%",
    	overflow: "auto",
      padding: theme.spacing(0),
      background: "none"
  	},

  	contentWrapper: {
    	display: "flex",
   	height: "100%",
    	overflowY: "auto",
    	flexDirection: "column",
      padding: theme.spacing(0),
  	},
}));

const MainContainer = ({ children }) => {
  	const classes = useStyles();
  	return (
   	<Container className={classes.mainContainer} maxWidth={false}>
      	<div className={classes.contentWrapper}>{children}</div>
    	</Container>
  	);
};
export default MainContainer;