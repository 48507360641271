/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo MainListItens
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, Tooltip } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import CodeIcon from '@material-ui/icons/Code';

function ListItemLink(props) {
  	const { icon, primary, to, className } = props;
  	const renderLink = React.useMemo(() =>
      React.forwardRef((itemProps, ref) => (
        	<RouterLink to={to} ref={ref} {...itemProps} />
      )),
    	[to]
  	);
  	var labelTitle; 
  	if(to==="/"){
		labelTitle = "Dashboard";
	} else if(to==="/connections"){
		labelTitle = "Leitura QR code";
	} else if(to==="/tickets"){
		labelTitle = "WhatsApp Painel";
	} else if(to==="/contacts"){
		labelTitle = "Lista de Contatos";
	} else if(to==="/quickAnswers"){
		labelTitle = "Respostas rápidas";
	} else if(to==="/users"){
		labelTitle = "Lista de Usuários";
	} else if(to==="/queues"){
		labelTitle = "Listar Setores";
	} else if(to==="/settings"){
		labelTitle = "Configurações";
	} else if(to==="/api"){
		labelTitle = "Documentação API";
	}
  	return (
   	<li>
      	<ListItem button component={renderLink} className={className}>
    			<Tooltip arrow placement="top" title={labelTitle}>
        			{icon ? <ListItemIcon>{icon}</ListItemIcon> :""}
				</Tooltip>
        		<ListItemText primary={primary} />
      	</ListItem>
    	</li>
  	);
}

const MainListItems = (props) => {
  	const { drawerClose } = props;
  	const { whatsApps } = useContext(WhatsAppsContext);
  	const { user } = useContext(AuthContext);
  	const [connectionWarning, setConnectionWarning] = useState(false);
  	const userEmail = process.env.REACT_APP_EMAIL_REFERENCE;
  	
  	useEffect(() => {
   	const delayDebounceFn = setTimeout(() => {
      	if(whatsApps.length > 0) {
        		const offlineWhats = whatsApps.filter((whats) => {
          		return (
            		whats.status === "qrcode" ||
            		whats.status === "PAIRING" ||
            		whats.status === "DISCONNECTED" ||
            		whats.status === "TIMEOUT" ||
            		whats.status === "OPENING"
          		);
        		});
        		if (offlineWhats.length > 0) {
          		setConnectionWarning(true);
        		} else {
          		setConnectionWarning(false);
        		}
      	}
    	}, 2000);
    	return () => clearTimeout(delayDebounceFn);
  	}, [whatsApps]);

  	return (
   	<div onClick={drawerClose}
   		style={{
		    	alignItems: "center",
		    	justifyContent: "flex-start",
   			color:"#f2f2f2"
   		}}>
	      {/**
	      <Can
	        role={user.profile}
	        perform="dashboard:view"
	        yes={() => (
	          <ListItemLink
	            to="/"
	            primary="Dashboard"
	            icon={<DashboardOutlinedIcon style={{color:"white"}} />}
	          />
	        )}
	      />
			**/}
      	<ListItemLink to="/" primary="Dashboard"
        		icon={<DashboardOutlinedIcon style={{color:"white"}} />}
        	/>
      	<ListItemLink to="/connections"
      		primary={i18n.t("mainDrawer.listItems.connections")}
        		icon={
         		<Badge overlap="rectangular" badgeContent={connectionWarning ? "!" : 0} color="error">
            		<SyncAltIcon style={{color:"white",fontSize:"1.6em"}} />
          		</Badge>
        		}
        	/>
      	<ListItemLink to="/tickets"
        		primary={i18n.t("mainDrawer.listItems.tickets")}
        		icon={<WhatsAppIcon style={{color:"white",fontSize:"1.8em"}} />}
        	/>
      	<ListItemLink to="/contacts"
        		primary={i18n.t("mainDrawer.listItems.contacts")}
        		icon={<ContactPhoneOutlinedIcon style={{color:"white",fontSize:"1.6em"}} />}
        	/>
      	<ListItemLink to="/quickAnswers"
        		primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        		icon={<QuestionAnswerOutlinedIcon style={{color:"white",fontSize:"1.6em"}} />}
        	/>
        	
         { user.email === userEmail ? 
	      	<Can style={{}}
	        		role={user.profile}
	        		perform="drawer-admin-items:view"
	        		yes={() => (
	          		<>
	            		<Divider />
	            		<ListSubheader inset style={{fontSize:"16px",color:"gray"}}>
	              			{i18n.t("mainDrawer.listItems.administration")}
	            		</ListSubheader>
							{/** Alteração para API **/}
							<ListItemLink to="/api"
			              	primary={i18n.t("mainDrawer.listItems.api")}
			              	icon={<CodeIcon style={{color:"white"}} />}
			            />
	            		<ListItemLink to="/users"
	              			primary={i18n.t("mainDrawer.listItems.users")}
	              			icon={<PeopleAltOutlinedIcon style={{color:"white"}} />}
	              		/>
	            		<ListItemLink to="/queues"
	              			primary={i18n.t("mainDrawer.listItems.queues")}
	              			icon={<AccountTreeOutlinedIcon style={{color:"white"}} />}
	              		/>
	            		<ListItemLink to="/settings"
	              			primary={i18n.t("mainDrawer.listItems.settings")}
	              			icon={<SettingsOutlinedIcon style={{color:"white"}} />}
	              		/>
	          		</>
	        		)}
	      	/>
         : null}
    	</div>
  	);
};
export default MainListItems;