/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Contatos
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Hidden from "@material-ui/core/Hidden";
// import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import ImportIcon from "@material-ui/icons/PlaylistAdd";

import ContactPhoneOutlinedIcon from "@material-ui/icons/Dialpad";
import AddIcon from "@material-ui/icons/AddCircleOutlined";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";

const useStyles = makeStyles((theme) => ({
  	mainPaper: {
   	display: 1,
    	height: "calc(100vh - 56px)",
 		padding: theme.spacing(0.2),
 		overflowY: "auto",
 		...theme.scrollbarStyles,
 		background: "#fafafa",
 		fontWeight: "normal",
  	},
  	table: {
		fontFamily:"Verdana",
    	"& .MuiTableCell-root": {
      	border:"1px solid rgba(224, 224, 224, 1)",
    	},
 		fontWeight: "normal",
  	}
}));

const reducer = (state, action) => {
  	if(action.type === "LOAD_CONTACTS") {
   	const contacts = action.payload;
    	const newContacts = [];

    	contacts.forEach((contact) => {
      	const contactIndex = state.findIndex((c) => c.id === contact.id);
      	if(contactIndex !== -1) {
        		state[contactIndex] = contact;
      	} else {
        		newContacts.push(contact);
      	}
    	});
    	return [...state, ...newContacts];
  	}

  	if(action.type === "UPDATE_CONTACTS") {
    	const contact = action.payload;
    	const contactIndex = state.findIndex((c) => c.id === contact.id);

    	if(contactIndex !== -1) {
      	state[contactIndex] = contact;
      	return [...state];
    	} else {
      	return [contact, ...state];
    	}
  	}

  	if(action.type === "DELETE_CONTACT") {
   	const contactId = action.payload;
    	const contactIndex = state.findIndex((c) => c.id === contactId);
    	
    	if(contactIndex !== -1) {
      	state.splice(contactIndex, 1);
    	}
    	return [...state];
  	}
  	if(action.type === "RESET") {
    	return [];
  	}
};

const StyledTableRow = withStyles((theme) => ({
  	root: {
		fontFamily:"Verdana",
   	'&:nth-of-type(odd)': {
      	backgroundColor: theme.palette.action.hover,
    	},
 		fontWeight: "normal",
  	},
}))(TableRow);

const Contacts = () => {
  	const classes = useStyles();
  	const history = useHistory();
  	const { user } = useContext(AuthContext);
  	const [loading, setLoading] = useState(false);
  	const [pageNumber, setPageNumber] = useState(1);
  	const [searchParam, setSearchParam] = useState("");
  	const [contacts, dispatch] = useReducer(reducer, []);
  	const [selectedContactId, setSelectedContactId] = useState(null);
  	const [contactModalOpen, setContactModalOpen] = useState(false);
  	const [deletingContact, setDeletingContact] = useState(null);
  	const [confirmOpen, setConfirmOpen] = useState(false);
  	const [hasMore, setHasMore] = useState(false);

  	useEffect(() => {
   	dispatch({ type: "RESET" });
    	setPageNumber(1);
  	}, [searchParam]);

  	useEffect(() => {
   	setLoading(true);
    	const delayDebounceFn = setTimeout(() => {
      	const fetchContacts = async () => {
        		try {
          		const { data } = await api.get("/contacts/", {params:{ searchParam, pageNumber },
          	});
          	dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          	setHasMore(data.hasMore);
          	setLoading(false);
        	} catch (err) {
         	toastError(err);
        	}
      };
      fetchContacts();
   }, 500);
   return () => clearTimeout(delayDebounceFn);
}, [searchParam, pageNumber]);

useEffect(() => {
   const socket = openSocket();
   socket.on("contact", (data) => {
      if(data.action === "update" || data.action === "create") {
      	dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }
      if(data.action === "delete") {
        	dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
   });
   return () => {
      socket.disconnect();
   };
}, []);

const handleSearch = (event) => {
   setSearchParam(event.target.value.toLowerCase());
};

const handleOpenContactModal = () => {
   setSelectedContactId(null);
   setContactModalOpen(true);
};

const handleCloseContactModal = () => {
   setSelectedContactId(null);
   setContactModalOpen(false);
};

const handleSaveTicket = async (contactId) => {
   if(!contactId) return;
    	setLoading(true);
    	try {
      	const { data: ticket } = await api.post("/tickets", {
        		contactId: contactId,
        			userId: user?.id,
        			status: "open",
      	});
      	history.push(`/tickets/${ticket.id}`);
    	} catch (err) {
      	toastError(err);
    	}
    	setLoading(false);
  	};

  	const hadleEditContact = (contactId) => {
   	setSelectedContactId(contactId);
    	setContactModalOpen(true);
  	};

  	const handleDeleteContact = async (contactId) => {
   	try {
      	await api.delete(`/contacts/${contactId}`);
      	toast.success(i18n.t("contacts.toasts.deleted"));
    	} catch (err) {
      	toastError(err);
    	}
    	setDeletingContact(null);
    	setSearchParam("");
    	setPageNumber(1);
  	};

  	const handleimportContact = async () => {
   	try {
      	await api.post("/contacts/import");
      	history.go(0);
    	} catch (err) {
      	toastError(err);
    	}
  	};

  	const loadMore = () => {
   	setPageNumber((prevState) => prevState + 1);
  	};

  	const handleScroll = (e) => {
   	if(!hasMore || loading) return;
    		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    	if(scrollHeight - (scrollTop + 100) < clientHeight) {
      	loadMore();
    	}
  	};

  	return (
   	<MainContainer style={{}}>
      	<ContactModal aria-labelledby="form-dialog-title"
        		open={contactModalOpen}
        		onClose={handleCloseContactModal}
        		contactId={selectedContactId}>
      	</ContactModal>
      	<ConfirmationModal
        		title = {
          		deletingContact ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name}?`:`${i18n.t("contacts.confirmationModal.importTitlte")}`}
        			open={confirmOpen}
        			onClose={setConfirmOpen}
        			onConfirm={(e) =>
          		deletingContact ? handleDeleteContact(deletingContact.id):handleimportContact()
        		}>
        		{deletingContact ? `${i18n.t("Tem certeza que deseja deletar este contato? Todos as Mensagens relacionadas serão Excluidas.")}`:`${i18n.t("contacts.confirmationModal.importMessage")}`}
      	</ConfirmationModal>
      
      	<MainHeader style={{}}>
	        	<ContactPhoneOutlinedIcon style={{padding:2,marginLeft:8,color:"white",fontSize:"2.2em",marginTop:"-5px",marginRight:"5px"}} />
	        	<Title style={{fontFamily:"Tahoma",fontWeight:"normal"}}>
	         	<span style={{fontSize:"0.8em",color:"white"}}>{i18n.t("Contatos")}</span>
	        	</Title>
        		<MainHeaderButtonsWrapper>
          		<TextField type="search" placeholder={i18n.t("Pesquisar")} size="medium"
		         	style={{
		         		top:4,
		         		width:"250px",
		         		textTransform:"capitalize",
		         		fontFamily:"Tahoma",
		         		fontSize:"15px",
		         		borderRadius:"5px",
		         		color: "#black",
		         		background:"#f8f8f8",
		         	}}
            		value={searchParam}
            		onChange={handleSearch}
            		InputProps={{
              			startAdornment: (
                			<InputAdornment position="start">
                  			<SearchIcon style={{margin:"8px",color:"gray"}} />
                			</InputAdornment>
              			),
            		}}
            	/>
	            <Hidden only={["sm","xs"]}>
			         <Button variant="contained" color="primary" size="small"
			         	style={{
			         		padding: "5px 25px 5px 25px",
			         		textTransform:'capitalize',
			         		fontFamily:"Tahoma",
			         		fontSize:"15px",
			         		borderRadius:"5px",
			         		color: "white"
			         	}}
			            onClick={(e) => setConfirmOpen(true)}>
	          			<ImportIcon style={{fontSize:"1.2em",color:"primary",marginRight:"2px"}} />
	         			{i18n.t("Importar Contatos")}
	       			</Button>
	       			<Button variant="contained" color="primary" size="small"
			         	style={{
			         		padding: "5px 25px 5px 25px",
			         		textTransform:'capitalize',
			         		fontFamily:"Tahoma",
			         		fontSize:"15px",
			         		borderRadius:"5px",
			         		color: "white"
			         	}}
	         			onClick={handleOpenContactModal}>
	          			<AddIcon style={{fontSize:"1.2em",color:"primary",marginRight:"2px"}} />
	         			{i18n.t("Adicionar contato")}
	       			</Button>
	            </Hidden>
        		</MainHeaderButtonsWrapper>
      	</MainHeader>
	      
	      <Paper className={classes.mainPaper} onScroll={handleScroll}>
        		<Table size="small" className={classes.table}>
       			<TableHead style={{backgroundColor:"#fdfdfd"}}>
            		<TableRow>
              			<TableCell padding="checkbox" style={{paddingRight:0,width:"5%"}} />
              			<TableCell style={{color:"#1f28a5",fontSize:"16px",width:"30%"}}>{i18n.t("Nome Contato")}</TableCell>
              			<TableCell style={{color:"#1f28a5",fontSize:"16px",width:"15%"}} align="center">{i18n.t("Celular WhatsApp")}</TableCell>
	            		<Hidden only={["sm","xs"]}>
	              			<TableCell style={{color:"#1f28a5",fontSize:"16px",width:"80%"}} align="center">
	                			{i18n.t("Opções de gerenciamento")}
	              			</TableCell>
	              		</Hidden>
            		</TableRow>
          		</TableHead>
          		<TableBody style={{fontWeight:"normal"}}>
            		<>
              		{contacts.map((contact) => (
                		<StyledTableRow key={contact.id} >
                  		<TableCell style={{paddingRight:0,width:"5%"}}>
                    			{<Avatar src={contact.profilePicUrl} style={{width:32,height:32}} />}
                  		</TableCell>
                  		<TableCell>{contact.name}</TableCell>
                  		<TableCell align="center">{contact.number}</TableCell>
	            			<Hidden only={["sm","xs"]}>
	                  		<TableCell align="center">
		                    		<Button style={{width:"15%",fontWeight:"normal",textTransform:"capitalize",color:"green",marginRight:5}}
		                      		size="small" variant="outlined"
		                      		onClick={() => handleSaveTicket(contact.id)}>
		                      		<WhatsAppIcon style={{marginRight:2}} /> {i18n.t("Acessar")}
		                    		</Button>
		                    		<Button style={{width:"15%",fontWeight:"normal",textTransform:"capitalize",marginRight:5}}
		                      		size="small" variant="outlined" color="primary"
		                      		onClick={() => hadleEditContact(contact.id)}>
		                      		<EditIcon style={{marginRight:2}} /> Editar
		                    		</Button>
		                    		<Can
		                      		role={user.profile}
		                      		perform="contacts-page:deleteContact"
		                      		yes={() => (
		                        		<Button style={{width:"15%",fontWeight:"normal",textTransform:"capitalize"}}
		                          			size="small" variant="outlined" color="secondary"
		                          			onClick={(e) => {
		                            			setConfirmOpen(true);
		                            			setDeletingContact(contact);
		                          			}}>
		                          			<DeleteOutlineIcon style={{marginRight:2}} /> Excluir
		                        		</Button>
		                      		)} />
	                  		</TableCell>
	              			</Hidden>
                		</StyledTableRow>
              		))}
              		{loading && <TableRowSkeleton avatar columns={2} />}
            		</>
          		</TableBody>
        		</Table>
      	</Paper>
    	</MainContainer>
  	);
};
export default Contacts;
