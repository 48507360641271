/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Gerenciador CHAT
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";
import Hidden from "@material-ui/core/Hidden";
//import ConnectedIcon from "./connected.png";
import ConnectedIcon from "./Logo_circle.png";

import Ticket from "../../components/Ticket/";
import TicketsManager from "../../components/TicketsManager/";

const useStyles = makeStyles((theme) => ({
  	chatContainer: {
   	flex: 1,
    	height: `calc(100% - 0px)`,
		padding: theme.spacing(1),
	   overflowY: "hidden",
		backgroundImage: "linear-gradient(to top, #161618,#36363a,#55555b,#161618)",
		border: 0,
   },
  	chatPapper: {
    	display: "flex",
    	height: "100%",
		border: 0,
  	},
  	// LD Esquerdo Panel
  	contactsWrapper: {
    	display: "flex",
    	height: "100%",
    	flexDirection: "column",
    	overflowY: "hidden",

    	backgroundColor: "inherit",
		border: 0,
    	borderTopLeftRadius: 15,
    	borderBottomLeftRadius: 15,
  		borderRight:"1px solid #f2f2f2",
  	},
  	// LD Esquerdo Panel (Carregado)
  	contactsWrapperSmall: {
    	display: "flex",
    	height: "100%",
    	flexDirection: "column",
    	overflowY: "hidden",
    	[theme.breakpoints.down("sm")]: {
      	display: "none",
    	},
    	backgroundColor: "inherit",
  		//border:"1px solid #e3e3e6",
		border: 0,
    	borderTopLeftRadius: 15,
    	borderBottomLeftRadius: 15,
		//borderRight: 0,
  	},
  	welcomeMsg: {
    	display: "flex",
    	justifyContent: "space-evenly",
    	alignItems: "center",
    	height: "100%",
    	textAlign: "center",
      fontFamily: "Arial",
    	//backgroundColor: "inherit",
    	
		border: 0,
    	borderTopRightRadius: 15,
		borderBottomRightRadius: 15,
   	//backgroundColor: "#f8f8f8",
      //border: "0px solid #444",
      //borderLeft: "1px solid #adacb3",
  	},
  	messagessWrapper: {
    	display: "flex",
    	height: "100%",
    	flexDirection: "column",
  	},
  	ticketsManagerClosed: {
   	[theme.breakpoints.down("sm")]: {
      	display: "none",
    	},
  	},
  	panelMain: {
		display:"block",
		padding: 2,
		fontSize:"1.3em",
		fontFamily:"Arial",
		color: "#234885",
	},
  	panelImgMain: {
		width: "65%",
		height: "auto",
	}
}));

const Chat = () => {
  	const classes = useStyles();
  	const { ticketId } = useParams();
  	return (
      <div className={classes.chatContainer}>
      	<div elevation={2} className={classes.chatPapper} >
            <Grid container spacing={0}>
	            {/*** Painel Esquerdo ***/}
	          	<Grid item lg={4} md={4} sm={4} xs={12} className={ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper}>
	            	<TicketsManager />
	          	</Grid>
	            {/*** Painel Direito ***/}
          		<Grid item lg={8} md={8} sm={8} xs={12} className={classes.messagessWrapper}>
            		{ticketId ? (
              			<>
                			<Ticket />
              			</>
            		) : (
	              		<Hidden only={["sm","xs"]}>
                        <Paper square className={classes.welcomeMsg}>
                           <div>
                              <img src={ConnectedIcon} className={classes.panelImgMain} aria-hidden alt={"image"} /> 
                              <div className={classes.panelMain}>
                                 {i18n.t("Selecione um Contato para começar a conversar.")}
                              </div>
                           </div>
                        </Paper>
	              		</Hidden>
            		)}
          		</Grid>
        		</Grid>
      	</div>
      </div>
  	);
};
export default Chat;