	/* ==================================================================================================================================
   * Dashboard WhatsApp WEB - Gerenciador de mensagens
   * ==================================================================================================================================
   * Copyright (C) 2010 - 2022
   * Programador: Fabio P. vilas Boas 
   *
   *  - fabio.2705@hotmail.com
   *  - multi-clinicas@hotmail.com
   *  - multiclinicas.suporte@gmail.com
   *  - Skype: Multi Clinicas
   *
   * Modulo Modal Tranferir Conversa
   *
   * Projeto Original
   * https://github.com/canove/whaticket
   * Coded by WhatsApp WEB
   * ----------------------------------------------------------------------------------------------------------------------------------
   * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
	import React, { useState, useEffect } from "react";
	import { useHistory } from "react-router-dom";

	import Button from "@material-ui/core/Button";
	import TextField from "@material-ui/core/TextField";
	import Dialog from "@material-ui/core/Dialog";
	import Select from "@material-ui/core/Select";
	import FormControl from "@material-ui/core/FormControl";
	import InputLabel from "@material-ui/core/InputLabel";
	import MenuItem from "@material-ui/core/MenuItem";
	import { makeStyles } from "@material-ui/core";

	import DialogActions from "@material-ui/core/DialogActions";
	import DialogContent from "@material-ui/core/DialogContent";
	import DialogTitle from "@material-ui/core/DialogTitle";
	import Autocomplete, { createFilterOptions,	} from "@material-ui/lab/Autocomplete";
	import CircularProgress from "@material-ui/core/CircularProgress";
	
	import { blue } from "@material-ui/core/colors";
	import AccountBoxIcon from "@material-ui/icons/AccountBox";
	import CloseIcon from "@material-ui/icons/CloseOutlined";
	import CheckIcon from "@material-ui/icons/Done";
	
	import { i18n } from "../../translate/i18n";
	import api from "../../services/api";
	import ButtonWithSpinner from "../ButtonWithSpinner";
	import toastError from "../../errors/toastError";
	import useQueues from "../../hooks/useQueues";

	const useStyles = makeStyles((theme) => ({
	  maxWidth: {
	    width: "100%",
	  },
	}));

	const filterOptions = createFilterOptions({
		trim: true,
	});

	const TransferTicketModal = ({ modalOpen, onClose, ticketid }) => {
		const history = useHistory();
		const [options, setOptions] = useState([]);
		const [queues, setQueues] = useState([]);
		const [allQueues, setAllQueues] = useState([]);
		const [loading, setLoading] = useState(false);
		const [searchParam, setSearchParam] = useState("");
		const [selectedUser, setSelectedUser] = useState(null);
		const [selectedQueue, setSelectedQueue] = useState('');
		const classes = useStyles();
		const { findAll: findAllQueues } = useQueues();

		useEffect(() => {
			const loadQueues = async () => {
				const list = await findAllQueues();
				setAllQueues(list);
				setQueues(list);
			}
			loadQueues();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			if (!modalOpen || searchParam.length < 3) {
				setLoading(false);
				return;
			}
			setLoading(true);
			const delayDebounceFn = setTimeout(() => {
				const fetchUsers = async () => {
					try {
						const { data } = await api.get("/users/", {
							params: { searchParam },
						});
						setOptions(data.users);
						setLoading(false);
					} catch (err) {
						setLoading(false);
						toastError(err);
					}
				};

				fetchUsers();
			}, 500);
			return () => clearTimeout(delayDebounceFn);
		}, [searchParam, modalOpen]);

		const handleClose = () => {
			onClose();
			setSearchParam("");
			setSelectedUser(null);
		};

		const handleSaveTicket = async e => {
			e.preventDefault();
			if (!ticketid) return;
			setLoading(true);
			try {
				let data = {};

				if (selectedUser) {
					data.userId = selectedUser.id;
					/**Incluso**/
					data.transf = true;
				}

				if (selectedQueue && selectedQueue !== null) {
					data.queueId = selectedQueue

					if (!selectedUser) {
						data.status = 'pending';
						data.userId = null;
						/**Incluso**/
						data.transf = true;
					}
				}
				await api.put(`/tickets/${ticketid}`, data);
				setLoading(false);
				history.push(`/tickets`);
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		};

		return (
			<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper" style={{boxShadow: "0px 15px 25px 0px rgba(0,0,0,.3)"}}>
				<form onSubmit={handleSaveTicket}>
					<DialogTitle id="form-dialog-title" style={{borderBottom: "1px solid green[500]", backgroundColor: blue[500]}}>
						<DialogActions style={{float: "left", marginTop: "-8px"}}>
			          	<AccountBoxIcon style={{color: "#ffffff", fontSize: "1.6em"}} />
			          	<spam style={{color: "#ffffff", marginTop: "0px", marginLeft: "5px", fontWeight: "normal"}}>
								{"Transferir Conversa"}
							</spam>	
						</DialogActions>
						<br />
					</DialogTitle>
					<DialogContent dividers style={{width: "450px", height: "auto", overflow: "hidden"}}>
						<Autocomplete
							style={{ width: 410, marginBottom: 20 }}
							getOptionLabel={option => `${option.name}`}
							onChange={(e, newValue) => {
								setSelectedUser(newValue);
								if (newValue != null && Array.isArray(newValue.queues)) {
									setQueues(newValue.queues);
								} else {
									setQueues(allQueues);
									setSelectedQueue('');
								}
							}}
							options={options}
							filterOptions={filterOptions}
							freeSolo
							autoHighlight
							noOptionsText={i18n.t("transferTicketModal.noOptions")}
							loading={loading}
							renderInput={params => (
								<TextField
									{...params}
									label={i18n.t("transferTicketModal.fieldLabel")}
									variant="outlined"
									required
									autoFocus
									onChange={e => setSearchParam(e.target.value)}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
						<FormControl variant="outlined" className={classes.maxWidth}>
							<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
							<Select
								value={selectedQueue}
								onChange={(e) => setSelectedQueue(e.target.value)}
								label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}>
								<MenuItem value={''}>&nbsp;</MenuItem>
								{queues.map((queue) => (
									<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button
		            	style={{ textTransform: "capitalize", fontFamily: "Verdana"}}
							onClick={handleClose}
							color="secondary"
							disabled={loading}
							variant="outlined">
		          		<CloseIcon style={{ marginRight: "2px"}} />
							{i18n.t("transferTicketModal.buttons.cancel")}
						</Button>
						<ButtonWithSpinner
		            	style={{ textTransform: "capitalize", fontFamily: "Verdana"}}
							variant="contained"
							type="submit"
							color="primary"
							loading={loading}>
		          		<CheckIcon style={{ marginRight: "2px"}} />
							{i18n.t("transferTicketModal.buttons.ok")}
						</ButtonWithSpinner>
					</DialogActions>
				</form>
			</Dialog>
		);
	};
	export default TransferTicketModal;