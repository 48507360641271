/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Modal Confirm
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { i18n } from "../../translate/i18n";

const ConfirmationModal = ({ title, children, open, onClose, onConfirm }) => {
	return (
		<Dialog open={open} onClose={() => onClose(false)} aria-labelledby="confirm-dialog">
			<DialogTitle style={{backgroundColor:"red",color:"white"}} id="confirm-dialog">{title}</DialogTitle>
			<DialogContent dividers>
				<Typography>{children}</Typography>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="default" onClick={() => onClose(false)} style={{textTransform:"capitalize"}}>
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
				<Button variant="contained" color="secondary" style={{textTransform:"capitalize"}}
					onClick={() => {
						onClose(false);
						onConfirm();
					}}>
					{i18n.t("Confirmar")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ConfirmationModal;