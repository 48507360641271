/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Message List CHAT
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useState, useEffect, useReducer, useRef } from "react";
import { isSameDay, parseISO, format } from "date-fns";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import {	Button, CircularProgress, Divider, IconButton, makeStyles } from "@material-ui/core";
import { AccessTime, Block, Done, DoneAll, ExpandMore, GetApp } from "@material-ui/icons";
import { /*blue,*/ red } from "@material-ui/core/colors";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
	
const useStyles = makeStyles((theme) => ({
	messagesListWrapper: {
    	overflow: "hidden",
    	position: "relative",
    	display: "flex",
    	flexDirection: "column",
    	flexGrow: 1,
	},
  	circleLoading: {
   	color: "white",
    	position: "absolute",
    	opacity: "70%",
    	top: 0,
    	left: "50%",
    	marginTop: 12,
  	},
  	messagesList: {
		backgroundImage: `url(${whatsBackground})`,
    	display: "flex",
    	flexDirection: "column",
    	flexGrow: 1,
    	padding: "25px 40px",
    	overflowY: "scroll",
    	[theme.breakpoints.down("sm")]: {
      	paddingBottom: "90px",
    	},
    	...theme.scrollbarStyles,
  	},
  	messageLeft: {
		marginTop: 10,
    	minWidth: 250,
    	maxWidth: 525,
    	height:   "auto",
    	display: "block",
    	position: "relative",
    	"&:hover #messageActionsButton": {
      	display: "flex",
      	position: "absolute",
    		backgroundColor: "#f3f3f3",
    		color: "blue",
      	top: 0,
      	right: 0,
    	},
 		objectFit: "cover",
    	whiteSpace: "pre-wrap",
    	wordWrap: "break-word",
    	backgroundColor: "#f8f8f8",
    	color: "#303030",
    	alignSelf: "flex-start",
		textAlign: "justify",

    	border: 0,
    	borderRadius: 10,
    	borderTopLeftRadius: 0,
    	marginLeft: 5,
    	"&::after": {
			content:'" "',
			position: "absolute",
			float: "left",
			top:0,
			left:-10,
			width:50,
			height:20,
  			border: "1px solid #f8f8f8",
    		backgroundColor: "transparent !important",
			flexDirection: "column",
  			borderWidth: "10px 0px 5px 10px",
  			borderStyle: "solid",
  			borderColor: "#f8f8f8 transparent transparent transparent",
			transform: "rotate(0deg)",
		},
    	padding: 10,
  	},
  	messageRight: {
		marginTop: 10,
    	minWidth: 250,
    	maxWidth: 525,
    	height: "auto",
    	display: "block",
    	position: "relative",
    	"&:hover #messageActionsButton": {
      	display: "flex",
      	position: "absolute",
    		backgroundColor: "#f3f3f3",
    		color: "blue",
      	top: 0,
      	right: 0,
    	},
    	whiteSpace: "pre-wrap",
    	wordWrap: "break-word",
    	backgroundColor: "#c0e0c0",
    	color: "#303030",
    	alignSelf: "flex-end",
		textAlign: "justify",

    	border: 0,
    	borderRadius: 10,
    	borderTopRightRadius: 0,
    	marginRight: 5,
    	"&::after": {
			content:'" "',
			position: "absolute",
			float: "right",
			top:0,
			right:-10,
			width:50,
			height:20,
  			border: "1px solid #c0e0c0",
    		backgroundColor: "transparent !important",
			flexDirection: "column",
  			borderWidth: "10px 10px 5px 0px",
  			borderStyle: "solid",
  			borderColor: "#c0e0c0 transparent transparent transparent",
			transform: "rotate(0deg)",
		},
    	padding: 10,
  	},
  	quotedContainerLeft: {
    	display: "flex",
    	position: "relative",
    	overflow: "hidden",

    	width: "100%",
    	height:"auto",
    	border:"1px solid #e3f0fb",
    	backgroundColor: "#e3f0fb",
    	fontSize: 10,
    	borderRadius: 6,
  	},
  	quotedContainerRight: {
    	display: "flex",
    	position: "relative",
    	overflow: "hidden",
    	
    	width: "100%",
    	height:"auto",
    	border:"1px solid #e4f1e4",
    	backgroundColor:"#e4f1e4",
    	fontSize: 10,
    	borderRadius: 6,
  	},
  	quotedSideColorLeft: {
   	flex: "none",
    	width: "5px",
    	backgroundColor: "#6bcbef",

    	top:0,
    	margin:0,
    	marginRight:5,
    	paddingBottom:2,
  	},
  	quotedSideColorRight: {
   	flex: "none",
    	width: "5px",
    	backgroundColor: "#17306a",

    	top:0,
    	margin:0,
    	marginRight:5,
    	paddingBottom:2,
  	},
  	quotedMsg: {
    	width: "100%",
    	height:"auto",
    	display:"block",
    	whiteSpace:"pre-wrap",
    	overflow:"hidden",
  	},
  	messageActionsButton: {
   	display: "none",
    	position: "relative",
    	color: "#999",
    	zIndex: 1,
    	backgroundColor: "inherit",
    	opacity: "90%",
    	"&:hover, &.Mui-focusVisible": { 
    		backgroundColor: "inherit" 
    	},
  	},
  	messageContactName: {
   	display: "flex",
    	color: "#6bcbef",
    	fontWeight: 500,
  	},
  	/* Corpo da mensagem de Texto*/
	textContentItem: {
    	width: "100%",
    	height: "auto",
    	display: "block",
    	whiteSpace: "pre-wrap",
    	wordWrap: "break-word",
    	fontSize: 12,
    	marginBottom: 16,
	},
	/* Mensagem Deletada*/
  	textContentItemDeleted: {
   	fontStyle: "italic",
    	fontSize: 12,
    	color: "rgba(0, 0, 0, 0.36)",
    	overflowWrap: "break-word",
    	width: "100%",
	   height: "auto",
  	},
	/* Mensagem de Video*/
	messageMedia: {
    	objectFit: "cover",
	   minWidth: 250,
	   maxWidth: 325,
    	height: "calc(100vh / 3)",
    	borderRadius: 10,
 		margin: 0,
    	padding: 0,
	},
	/*Status da MSG / Horario do Envio*/
  	timestamp: {
   	fontStyle: "normal",
   	fontSize: 12,
    	position: "absolute",
    	bottom: 0,
    	right: 5,
    	padding: "2px 2px",
    	backgroundColor: "inherit",
    	color: "#254165",
  	},
	/*Mostra Intervalos dos DIAS*/
  	dailyTimestamp: {
   	alignItems: "center",
    	textAlign: "center",
    	alignSelf: "center",
	   fontWeight: 500,
    	width: "110px",
    	backgroundColor: "#e1f3fb",
    	color: "#0c0c0cff",
    	margin: 10,
    	borderRadius: 10,
  	},
	dailyTimestampText: {
		color: "#808888",
 		padding: 8,
 		alignSelf: "center",
 		marginLeft: "0px",
	},
	ackIcons: {
 		fontSize: 18,
 		verticalAlign: "middle",
 		marginLeft: 4,
	},
	deletedIcon: {
 		fontSize: 18,
 		verticalAlign: "middle",
 		marginRight: 4,
 		color: red[200]
	},
	deletedMsg: {
 		color: red[200]
	},
	ackDoneAllIcon: {
 		color: "#2b8e32",
 		fontSize: 16,
 		verticalAlign: "middle",
 		marginLeft: 4,
	},
	downloadMedia: {
 		display: "flex",
 		alignItems: "center",
 		justifyContent: "center",
 		backgroundColor: "#f2f7f9",
 		padding: 10,
	}
}));

const reducer = (state, action) => {
	console.log("Message List Action Type => "+action.type);
	
  	if(action.type === "LOAD_MESSAGES") {
    	const messages = action.payload;
    	const newMessages = [];
    	messages.forEach((message) => {
      	const messageIndex = state.findIndex((m) => m.id === message.id);
      	if(messageIndex !== -1) {
        		state[messageIndex] = message;
      	} else {
        		newMessages.push(message);
      	}
    	});
    	return [...newMessages, ...state];
  	}

  	if(action.type === "ADD_MESSAGE") {
    	const newMessage = action.payload;
    	const messageIndex = state.findIndex((m) => m.id === newMessage.id);
		
    	if(messageIndex !== -1) {
      	state[messageIndex] = newMessage;
    	} else {
      	state.push(newMessage);
    	}
    	return [...state];
  	}

  	if(action.type === "UPDATE_MESSAGE") {
    	const messageToUpdate = action.payload;
    	const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);
    	if(messageIndex !== -1) {
      	state[messageIndex] = messageToUpdate;
    	}
    	return [...state];
  	}
  	if(action.type === "RESET") {
    	return [];
  	}
};

const MessagesList = ({ ticketId, isGroup }) => {
  	const classes = useStyles();
  	const [messagesList, dispatch] = useReducer(reducer, []);
  	const [pageNumber, setPageNumber] = useState(1);
  	const [hasMore, setHasMore] = useState(false);
  	const [loading, setLoading] = useState(false);
  	const lastMessageRef = useRef();

  	const [selectedMessage, setSelectedMessage] = useState({});
  	const [anchorEl, setAnchorEl] = useState(null);
  	const messageOptionsMenuOpen = Boolean(anchorEl);
  	const currentTicketId = useRef(ticketId);

  	useEffect(() => {
   	dispatch({ type: "RESET" });
    	setPageNumber(1);
    	currentTicketId.current = ticketId;
  	}, [ticketId]);

  	useEffect(() => {
   	setLoading(true);
    	const delayDebounceFn = setTimeout(() => {
      	const fetchMessages = async () => {
        	try {
          	const { data } = await api.get("/messages/" + ticketId, {
            	params: { pageNumber },
          	});

          	if(currentTicketId.current === ticketId) {
            	dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            	setHasMore(data.hasMore);
            	setLoading(false);
          	}
          	if(pageNumber === 1 && data.messages.length > 1) {
            	scrollToBottom();
          	}
        	} catch (err) {
         	setLoading(false);
          	toastError(err);
        	}
      };
      fetchMessages();
   }, 500);
   return () => {
      clearTimeout(delayDebounceFn);
   };
}, [pageNumber, ticketId]);

useEffect(() => {
   const socket = openSocket();
   socket.on("connect", () => socket.emit("joinChatBox", ticketId));
   socket.on("appMessage", (data) => {
      if(data.action === "create") {
        	dispatch({ type: "ADD_MESSAGE", payload: data.message });
        	scrollToBottom();
      }
      if(data.action === "update") {
        	dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
   });
   return () => {
      socket.disconnect();
   };
}, [ticketId]);

const loadMore = () => {
   setPageNumber((prevPageNumber) => prevPageNumber + 1);
};

const scrollToBottom = () => {
   if(lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
   }
};

const handleScroll = (e) => {
   if(!hasMore) return;
   const { scrollTop } = e.currentTarget;
   if(scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
   }
   if(loading) {
      return;
   }
   if(scrollTop < 50) {
      loadMore();
   }
};

const handleOpenMessageOptionsMenu = (e, message) => {
   setAnchorEl(e.currentTarget);
   setSelectedMessage(message);
};
  	
const handleCloseMessageOptionsMenu = (e) => {
setAnchorEl(null);
};

const checkMessageMedia = (message) => {
	if(message.mediaType === "location" && message.body.split('|').length >= 2) {
		let locationParts = message.body.split('|')
		let imageLocation = locationParts[0]		
		let linkLocation = locationParts[1]
		let descriptionLocation = null
		if(locationParts.length > 2)
			descriptionLocation = message.body.split('|')[2]
		
		return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
	} else if (message.mediaType === "vcard") {
		let array = message.body.split("\n");
		let obj = [];
		let contact = "";
		for(let index = 0; index < array.length; index++) {
			const v = array[index];
			let values = v.split(":");
			for(let ind = 0; ind < values.length; ind++) {
				if(values[ind].indexOf("+") !== -1) {
					obj.push({ number: values[ind] });
				}
				if(values[ind].indexOf("FN") !== -1) {
					contact = values[ind + 1];
				}
			}
		}
		return <VcardPreview contact={contact} numbers={obj[0].number} />
	} else if(message.mediaType === "image") {
		console.log("Imagem => "+message.mediaUrl);
   	return <ModalImageCors imageUrl={message.mediaUrl} />;
	} else if (message.mediaType === "audio") {
		console.log("Audio => "+message.mediaUrl);
		return (
  			<audio controls>
   			<source type="audio/ogg" src={message.mediaUrl}></source>
  			</audio>
		);
	} else if (message.mediaType === "video") {
		console.log("Video => "+message.mediaUrl);
		return (
			<video className={classes.messageMedia} src={message.mediaUrl} controls />
		);
	} else {
		return (
  			<>
   		<div className={classes.downloadMedia}>
      		<Button href={message.mediaUrl} startIcon={<GetApp />} color="primary" variant="outlined" target="_blank">
        			Download
      		</Button>
   		</div>
   		<Divider />
  			</>
		);
	}
};

const renderMessageAck = (message) => {
   if(message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
   }
   if(message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
   }
   if(message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
   }
   if(message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
   }
};

const renderDailyTimestamps = (message, index) => {
   if(index === 0) {
   	return (
        	<span
         	className={classes.dailyTimestamp}
          	key={`timestamp-${message.id}`}>
          	<div className={classes.dailyTimestampText}>
            	{format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          	</div>
        	</span>
      );
   }
   if(index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if(!isSameDay(messageDay, previousMessageDay)) {
	      return (
         	<span
            	className={classes.dailyTimestamp}
            	key={`timestamp-${message.id}`}>
            	<div className={classes.dailyTimestampText}>
              		{format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            	</div>
         	</span>
      	);
      }
   }
   if(index === messagesList.length - 1) {
      return (
        	<div
         	key={`ref-${message.createdAt}`}
          	ref={lastMessageRef}
          	style={{float:"left",clear:"both"}}
         />
      );
   }
};

const renderMessageDivider = (message, index) => {
   if(index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;
      if(messageUser !== previousMessageUser) {
        	return (
         	<span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        	);
      }
   }
};

const renderQuotedMessage = (message) => {
   return (
      <div
        	className={clsx(classes.quotedContainerLeft, {
         	[classes.quotedContainerRight]: message.fromMe,
        	})}>
        	<span
         	className={clsx(classes.quotedSideColorLeft, {
            	[classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          	})}>
        	</span>
        	<div className={classes.quotedMsg}>
          	{!message.quotedMsg?.fromMe && (
            	<span className={classes.messageContactName}>
              		{message.quotedMsg?.contact?.name}
            	</span>
          	)}
            <MarkdownWrapper>{message.quotedMsg?.body}</MarkdownWrapper>
        	</div>
      </div>
   );
};

const renderMessages = () => {
   if(messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
      	// MSG Left
        	if(!message.fromMe) {
          	return (
            	<React.Fragment key={message.id}>
              		{renderDailyTimestamps(message, index)}
              		{renderMessageDivider(message, index)}
              		<div className={classes.messageLeft}>
                		<IconButton variant="contained" size="small" id="messageActionsButton"
                  		disabled={message.isDeleted} className={classes.messageActionsButton}
                  		onClick={(e) => handleOpenMessageOptionsMenu(e, message)}>
                  		<ExpandMore />
                		</IconButton>
                		{isGroup && (
                  		<span className={classes.messageContactName}>
                    			{message.contact?.name}
                  		</span>
                		)}
	            		{message.body!==null && message.mediaType === "image" ? (
	            			<div style={{minWidth:250,maxWidth:300,height:"auto"}}>
			                	{(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard") && 
			                		checkMessageMedia(message)
			                	}
		                		<div className={classes.textContentItem} style={{width:"300px",height:"auto"}}>
		                  		{message.quotedMsg && renderQuotedMessage(message)}
		                  		<MarkdownWrapper>{message.body}</MarkdownWrapper>
		                  		<span className={classes.timestamp}>
		                    			{format(parseISO(message.createdAt), "HH:mm")}
		                  		</span>
		                		</div>
	            			</div>
	            		) : (
	            			<div>
			                	{(message.mediaUrl!==null || message.mediaType === "location" || message.mediaType === "vcard") && 
			                		checkMessageMedia(message)
			                	}
		                		<div className={classes.textContentItem}>
		                  		{message.quotedMsg && renderQuotedMessage(message)}
		                  		<MarkdownWrapper>{message.body}</MarkdownWrapper>
		                  		<span className={classes.timestamp}>
		                    			{format(parseISO(message.createdAt), "HH:mm")}
		                  		</span>
		                		</div>
		                	</div>
            			)}
              		</div>
            	</React.Fragment>
          	);
        	} else {
        		// MSG Right
         	return (
            	<React.Fragment key={message.id}>
              		{renderDailyTimestamps(message, index)}
              		{renderMessageDivider(message, index)}
              		<div className={classes.messageRight}>
                		<IconButton variant="contained" size="small" id="messageActionsButton"
                  		disabled={message.isDeleted} className={classes.messageActionsButton}
                  		onClick={(e) => handleOpenMessageOptionsMenu(e, message)}>
                  		<ExpandMore />
                		</IconButton>
	            		
	            		{message.body!==null && message.mediaType === "image" ? (
	                  	<div style={{minWidth:250,maxWidth:300,height:"auto"}}>
			                	{(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard") && 
			                		checkMessageMedia(message)
			                	}
		                		<div className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted})}>
		                  		{message.isDeleted && (
		                    			<Block color="disabled" fontSize="small" className={classes.deletedIcon} />
		                  		)}
		                  		{message.quotedMsg && renderQuotedMessage(message)}
		                  		{message.isDeleted ? (
		                  			<MarkdownWrapper>{i18n.t("Mensagem deletada!")}</MarkdownWrapper>
		                  		):(
		                  			<>
											{/*Corpo da MSG Texto*/}
											<MarkdownWrapper>{message.body}</MarkdownWrapper>
		                  			</>
		                  		)}
		                  		<span className={classes.timestamp}>
		                    			{format(parseISO(message.createdAt), "HH:mm")}
		                    			{/*Status da Mensagem*/}
		                    			{renderMessageAck(message)}
		                  		</span>
		               		</div>
	            			</div>
	            		) : (
	            			<div>
			                	{(message.mediaUrl!==null || message.mediaType === "location" || message.mediaType === "vcard") && 
			                		checkMessageMedia(message)
			                	}
		                		<div className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted})}>
		                  		{message.isDeleted && (
		                    			<Block color="disabled" fontSize="small" className={classes.deletedIcon} />
		                  		)}
		                  		{message.quotedMsg && renderQuotedMessage(message)}
		                  		{message.isDeleted ? (
		                  			<MarkdownWrapper>{i18n.t("Mensagem deletada!")}</MarkdownWrapper>
		                  		):(
		                  			<>
											{/*Corpo da MSG Texto*/}
											<MarkdownWrapper>{message.body}</MarkdownWrapper>
		                  			</>
		                  		)}
		                  		<span className={classes.timestamp}>
		                    			{format(parseISO(message.createdAt), "HH:mm")}
		                    			{/*Status da Mensagem*/}
		                    			{renderMessageAck(message)}
		                  		</span>
		               		</div>
		                	</div>
            			)}
              		</div>
            	</React.Fragment>
          	);
        	}
      });
      return viewMessagesList;
   } else {
      return <div>{i18n.t("Diga olá ao seu novo contato!")}</div>;
   }
};

return (
   <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu message={selectedMessage} anchorEl={anchorEl}
        	menuOpen={messageOptionsMenuOpen} handleClose={handleCloseMessageOptionsMenu} />
      	<div id="messagesList" className={classes.messagesList} onScroll={handleScroll}>
        		{messagesList.length > 0 ? renderMessages() : []}
      	</div>
      	{loading && (
        		<div>
          		<CircularProgress className={classes.circleLoading} />
        		</div>
      	)}
    	</div>
  	);
};
export default MessagesList;