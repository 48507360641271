/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Respostas Rápidas
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";

import { Tooltip, Button, IconButton, makeStyles, withStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, InputAdornment, TextField } from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/Dialpad";
import AddIcon from "@material-ui/icons/AddCircleOutlined";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import QuickAnswersModal from "../../components/QuickAnswersModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  	if(action.type === "LOAD_QUICK_ANSWERS") {
		const quickAnswers = action.payload;
    	const newQuickAnswers = [];

    	quickAnswers.forEach((quickAnswer) => {
      	const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
      	if(quickAnswerIndex !== -1) {
        		state[quickAnswerIndex] = quickAnswer;
      	} else {
        		newQuickAnswers.push(quickAnswer);
      	}
    	});
    	return [...state, ...newQuickAnswers];
  	}

  	if(action.type === "UPDATE_QUICK_ANSWERS") {
   	const quickAnswer = action.payload;
    	const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

    	if(quickAnswerIndex !== -1) {
      	state[quickAnswerIndex] = quickAnswer;
      	return [...state];
    	} else {
      	return [quickAnswer, ...state];
    	}
  	}

  	if(action.type === "DELETE_QUICK_ANSWERS") {
    	const quickAnswerId = action.payload;
    	const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId);
    	if(quickAnswerIndex !== -1) {
      	state.splice(quickAnswerIndex, 1);
    	}
    	return [...state];
  	}

  	if(action.type === "RESET") {
   	return [];
  	}
};

const useStyles = makeStyles((theme) => ({
  	mainPaper: {
   	display: 1,
    	height: "calc(100vh - 56px)",
 		padding: theme.spacing(0.2),
 		overflowY: "auto",
 		...theme.scrollbarStyles,
 		background: "#fafafa",
 		fontWeight: "normal",
  	},
  	table: {
		fontFamily:"Verdana",
    	"& .MuiTableCell-root": {
      	border:"1px solid rgba(224, 224, 224, 1)",
    	},
 		fontWeight: "normal",
  	}
}));
const StyledTableRow = withStyles((theme) => ({
  	root: {
		fontFamily:"Verdana",
   	'&:nth-of-type(odd)': {
      	backgroundColor: theme.palette.action.hover,
    	},
 		fontWeight: "normal",
  	},
}))(TableRow);


const QuickAnswers = () => {
  	const classes = useStyles();
  	const [loading, setLoading] = useState(false);
  	const [pageNumber, setPageNumber] = useState(1);
  	const [searchParam, setSearchParam] = useState("");
  	const [quickAnswers, dispatch] = useReducer(reducer, []);
  	const [selectedQuickAnswers, setSelectedQuickAnswers] = useState(null);
  	const [quickAnswersModalOpen, setQuickAnswersModalOpen] = useState(false);
  	const [deletingQuickAnswers, setDeletingQuickAnswers] = useState(null);
  	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  	const [hasMore, setHasMore] = useState(false);

  	useEffect(() => {
   	dispatch({ type: "RESET" });
    	setPageNumber(1);
  	}, [searchParam]);

  	useEffect(() => {
   	setLoading(true);
    	const delayDebounceFn = setTimeout(() => {
      	const fetchQuickAnswers = async () => {
        		try {
          		const { data } = await api.get("/quickAnswers/", {
            	params: { searchParam, pageNumber },
          	});
          	dispatch({ type: "LOAD_QUICK_ANSWERS", payload: data.quickAnswers });
          	setHasMore(data.hasMore);
          	setLoading(false);
        	} catch (err) {
         	toastError(err);
        	}
      };
      fetchQuickAnswers();
   }, 500);
   return () => clearTimeout(delayDebounceFn);
}, [searchParam, pageNumber]);

useEffect(() => {
   const socket = openSocket();
   socket.on("quickAnswer", (data) => {
      if(data.action === "update" || data.action === "create") {
        	dispatch({ type: "UPDATE_QUICK_ANSWERS", payload: data.quickAnswer });
      }
      if(data.action === "delete") {
        	dispatch({
         	type: "DELETE_QUICK_ANSWERS",
          	payload: +data.quickAnswerId,
        	});
      }
   });
   return () => {
   	socket.disconnect();
   };
}, []);

const handleSearch = (event) => {
   setSearchParam(event.target.value.toLowerCase());
};

const handleOpenQuickAnswersModal = () => {
   setSelectedQuickAnswers(null);
   setQuickAnswersModalOpen(true);
};

const handleCloseQuickAnswersModal = () => {
   setSelectedQuickAnswers(null);
   setQuickAnswersModalOpen(false);
};

const handleEditQuickAnswers = (quickAnswer) => {
   setSelectedQuickAnswers(quickAnswer);
   setQuickAnswersModalOpen(true);
};

const handleDeleteQuickAnswers = async (quickAnswerId) => {
   try {
   	await api.delete(`/quickAnswers/${quickAnswerId}`);
      toast.success(i18n.t("quickAnswers.toasts.deleted"));
   } catch (err) {
      toastError(err);
   }
   setDeletingQuickAnswers(null);
   setSearchParam("");
   setPageNumber(1);
};

const loadMore = () => {
   setPageNumber((prevState) => prevState + 1);
};

const handleScroll = (e) => {
   if(!hasMore || loading) return;
    	const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    	if(scrollHeight - (scrollTop + 100) < clientHeight) {
      	loadMore();
    	}
  	};

  	return (
   	<MainContainer>
      	<ConfirmationModal
        		title = {
          		deletingQuickAnswers && `${"Excluir está Resposta? "} ${ deletingQuickAnswers.shortcut }`
        		}
        		open={confirmModalOpen}
        		onClose={setConfirmModalOpen}
        		onConfirm={() => handleDeleteQuickAnswers(deletingQuickAnswers.id)}>
        		{i18n.t("quickAnswers.confirmationModal.deleteMessage")}
      	</ConfirmationModal>
      	<QuickAnswersModal
        		open={quickAnswersModalOpen}
        		onClose={handleCloseQuickAnswersModal}
        		aria-labelledby="form-dialog-title"
        		quickAnswerId={selectedQuickAnswers && selectedQuickAnswers.id}>
      	</QuickAnswersModal>
      	<MainHeader>
	        	<QuestionAnswerOutlinedIcon style={{padding:2,marginLeft:8,color:"white",fontSize:"2.2em",marginTop:"-5px",marginRight:"5px"}} />
	        	<Title style={{fontFamily:"Tahoma",fontWeight:"normal"}}>
	         	<span style={{fontSize:"0.8em",color:"white"}}>{i18n.t("Respostas rápidas")}</span>
	        	</Title>
        		<MainHeaderButtonsWrapper>
          		<TextField type="search" placeholder={i18n.t("Pesquisar")} size="medium"
		         	style={{
		         		top:4,
		         		width:"250px",
		         		textTransform:"capitalize",
		         		fontFamily:"Tahoma",
		         		fontSize:"15px",
		         		borderRadius:"5px",
		         		color: "#black",
		         		background:"#f8f8f8",
		         	}}
            		value={searchParam}
            		onChange={handleSearch}
            		InputProps={{
              			startAdornment: (
                			<InputAdornment position="start">
                  			<SearchIcon style={{margin:"8px",color:"gray"}} />
                			</InputAdornment>
              			),
            		}}
            	/>
	          	<Button variant="contained" color="primary" size="small"
	            	style={{
		         		padding: "5px 25px 5px 25px",
		         		textTransform:'capitalize',
		         		fontFamily:"Tahoma",
		         		fontSize:"15px",
		         		borderRadius:"5px",
		         		color: "white"
	            	}}
	            	onClick={handleOpenQuickAnswersModal}>
	          		<AddIcon style={{fontSize:"1.2em",color:"primary",marginRight:"2px"}} />
	            	{i18n.t("quickAnswers.buttons.add")}
	          	</Button>
        		</MainHeaderButtonsWrapper>
      	</MainHeader>
      	<Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
        		<Table size="small" className={classes.table}>
       			<TableHead style={{backgroundColor:"#fdfdfd"}}>
            		<TableRow>
              			<TableCell style={{color:"#1f28a5",fontSize:"16px",width:"12%"}} align="center">
                			{i18n.t("Titulo")}
              			</TableCell>
              			<TableCell style={{color:"#1f28a5",fontSize:"16px",width:"78%"}} align="center">
                			{i18n.t("quickAnswers.table.message")}
              			</TableCell>
              			<TableCell style={{color:"#1f28a5",fontSize:"16px",width:"10%"}} align="center">
                			{i18n.t("Opções")}
              			</TableCell>
            		</TableRow>
          		</TableHead>
          		<TableBody>
            		<>
              		{quickAnswers.map((quickAnswer) => (
                		<StyledTableRow key={quickAnswer.id}>
                  		<TableCell align="center">{quickAnswer.shortcut}</TableCell>
                  		<TableCell align="center">{quickAnswer.message}</TableCell>
                  		<TableCell align="center">
       							<Tooltip arrow title={"Editar Resposta"}>
	                    			<IconButton size="medium" color="primary"
	                      			onClick={() => handleEditQuickAnswers(quickAnswer)}>
	                      			<Edit />
	                    			</IconButton>
									</Tooltip>
       							<Tooltip arrow title={"Excluir Resposta"}>
	                    			<IconButton size="medium" color="secondary"
	                      			onClick={(e) => {
	                        			setConfirmModalOpen(true);
	                        			setDeletingQuickAnswers(quickAnswer);
	                      			}}>
	                      			<DeleteOutline />
	                    			</IconButton>
									</Tooltip>
                  		</TableCell>
                		</StyledTableRow>
              		))}
              		{loading && <TableRowSkeleton columns={3} />}
            		</>
          		</TableBody>
        		</Table>
      	</Paper>
    	</MainContainer>
  	);
};
export default QuickAnswers;