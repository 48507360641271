	/* ==================================================================================================================================
   * Dashboard WhatsApp WEB - Gerenciador de mensagens
   * ==================================================================================================================================
   * Copyright (C) 2010 - 2022
   * Programador: Fabio P. vilas Boas 
   *
   *  - fabio.2705@hotmail.com
   *  - multi-clinicas@hotmail.com
   *  - multiclinicas.suporte@gmail.com
   *  - Skype: Multi Clinicas
   *
   * Modulo TICKET´S, Preview LD Direito
   *
   * Projeto Original
   * https://github.com/canove/whaticket
   * Coded by WhatsApp WEB
   * ----------------------------------------------------------------------------------------------------------------------------------
   * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
	import React from "react";
	import { makeStyles } from "@material-ui/core/styles";
	import { Avatar, Card, CardHeader } from "@material-ui/core";
	import Skeleton from "@material-ui/lab/Skeleton";

	const useStyles = makeStyles(theme => ({
		ticketHeader: {
			display: "flex",
			flex: "none",
			borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
	    	height: "62px",
		},
	}));

	const TicketHeaderSkeleton = () => {
		const classes = useStyles();
		return (
			<Card square className={classes.ticketHeader}>
				<CardHeader
					titleTypographyProps={{ noWrap: true }}
					subheaderTypographyProps={{ noWrap: true }}
					avatar={
						<Skeleton animation="wave" variant="circle">
							<Avatar alt="contact_image" />
						</Skeleton>
					}
					title={<Skeleton animation="wave" width={80} />}
					subheader={<Skeleton animation="wave" width={140} />}
				/>
			</Card>
		);
	};
	export default TicketHeaderSkeleton;