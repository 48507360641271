/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo TICKET´S, Informação do Contato (Painel Direito Superior)
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* subheader={ ticket.user && `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`}>
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React from "react";
import { Avatar, CardHeader } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
	return (
		<CardHeader onClick={onClick}	titleTypographyProps={{noWrap:true}}
			subheaderTypographyProps={{noWrap:true}}
			avatar={<Avatar alt="image" src={contact.profilePicUrl} sx={{width:75,height:75}} />}
			title={<span style={{color:"#293d89"}}>{contact.name}</span>}
			subheader={ticket.user && `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`}>
		</CardHeader>
	)
};
export default TicketInfo;