/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Config
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by Multi Clinicas APP
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
function getConfig(name, defaultValue=null) {
   // If inside a docker container, use window.ENV
   if(window.ENV !== undefined ) {
      return window.ENV[name] || defaultValue;
   }
   return process.env[name] || defaultValue;
}

export function getBackendUrl() {
   return getConfig('REACT_APP_BACKEND_URL');
}

export function getHoursCloseTicketsAuto() {
   return getConfig('REACT_APP_HOURS_CLOSE_TICKETS_AUTO');
}

export function getConnectionsLimit() {
   return process.env.REACT_APP_CONNECTIONS_LIMIT || null;
}

export function getUserLimit() {
   return process.env.REACT_APP_USER_LIMIT || null;
}