	/* ==================================================================================================================================
   * Dashboard WhatsApp WEB - Gerenciador de mensagens
   * ==================================================================================================================================
   * Copyright (C) 2010 - 2022
   * Programador: Fabio P. vilas Boas 
   *
   *  - fabio.2705@hotmail.com
   *  - multi-clinicas@hotmail.com
   *  - multiclinicas.suporte@gmail.com
   *  - Skype: Multi Clinicas
   *
   * Modulo Gerenciador CHAT
   *
   * Projeto Original
   * https://github.com/canove/whaticket
   * Coded by WhatsApp WEB
   * ----------------------------------------------------------------------------------------------------------------------------------
   * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
	import React, { useState, useEffect } from "react";
	import { makeStyles } from "@material-ui/core/styles";
	import ModalImage from "react-modal-image";
	import api from "../../services/api";

	const useStyles = makeStyles(theme => ({
		messageMedia: {
	    	objectFit: "cover",
	    	//minWidth: 250,
	    	//maxWidth: 325,
	    	width: "100%",
	    	height: "auto",
	    	borderRadius: 10,
	 		margin: 0,
	    	padding: 0,
	    	border: "1px solid #f4f4f4",
		},
	}));

	const ModalImageCors = ({ imageUrl }) => {
		const classes = useStyles();
		const [fetching, setFetching] = useState(true);
		const [blobUrl, setBlobUrl] = useState("");

		useEffect(() => {
			if (!imageUrl) return;
			const fetchImage = async () => {
				const { data, headers } = await api.get(imageUrl, {
					responseType: "blob",
				});
				const url = window.URL.createObjectURL(
					new Blob([data], { type: headers["content-type"] })
				);
				setBlobUrl(url);
				setFetching(false);
			};
			fetchImage();
 			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [imageUrl]);

		return (
			<ModalImage alt="image"	className={classes.messageMedia}
            smallSrcSet={fetching ? imageUrl : blobUrl}
				medium={fetching ? imageUrl : blobUrl}
				large={fetching ? imageUrl : blobUrl}> 
			</ModalImage>
		);
	};
	export default ModalImageCors;