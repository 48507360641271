/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo TICKET´S, Opções dos Botões do HEADER LD Direito
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/AccountBalanceWallet";
import { MoreVert, ContactMail, ReplyAll } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Done";

import { i18n } from "../../translate/i18n";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
		borderTopRightRadius: 20,
	},
	styleButtons: {
		textTransform: "capitalize",
		borderRadius:  "5px",
		paddingLeft:   "15px",
		paddingRight:  "15px",
		fontFamily:    "Arial",
		fontSize: "1em",
	},
	returnButtom: {
		margin:2,
    	color: "#316fac",
    	boxShadow:"0 1px 1px 0 gainsboro",
		padding: "0.5rem",
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			if(status==="return"){
				await api.put(`/tickets/${ticket.id}`, {
					status: "open",
					sendModo: "local",
					userId: userId || null,
				});
			} else {
				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					sendModo: "local",
					userId: userId || null,
				});
			}
			setLoading(false);
			if(status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<div className={classes.actionButtons}>
    		{/*** Reabrir Mensagens **/}
			{ticket.status === "closed" && (
				<ButtonWithSpinner className={classes.styleButtons} variant="contained" color="primary"
					loading={true}
					startIcon={<ContactMail style={{marginRight:"2px"}} />}
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}>
					{i18n.t("Reabrir Mensagem")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
          		{/** Botão Retornar **/}
          		<Tooltip arrow title="Retornar a fila de Atendimento">
						<IconButton size="small" variant="outlined" className={classes.returnButtom}
							loading={true}
							onClick={e => handleUpdateTicketStatus(e, "return", user?.id)}>
							<ReplyAll style={{}} />
						</IconButton>
					</Tooltip>
          		{/** Botão Finalizar **/}
          		<Tooltip arrow title="Encerrar esse Atendimento">
						<IconButton size="small" variant="outlined" className={classes.returnButtom}
							loading={true}
							onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}>
							<CloseIcon style={{color:"#d90000"}} />
						</IconButton>
					</Tooltip>
          		{/** DropDown Transferir / Deletar **/}
          		<Tooltip arrow title="Transferir / Deletar Atendimento">
						<IconButton size="small" variant="outlined" className={classes.returnButtom}
							onClick={handleOpenTicketOptionsMenu}>
							<MoreVert style={{}} />
						</IconButton>
					</Tooltip>
					<TicketOptionsMenu ticket={ticket} anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}>
					</TicketOptionsMenu>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner style={{display:"none"}}
					title={"Aceitar Mensagem"}
					variant="contained" size="small" color="primary"
					loading={loading ? true:'undefined'}
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}>
          		<CheckIcon style={{ marginRight: "2px"}} />
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};
export default TicketActionButtons;