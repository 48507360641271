/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Index APP (Configura Cabeçalho) Arquivos LD Direito
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	contactsHeader: {
		display: "flex",
		alignItems: "center",
		backgroundImage: "linear-gradient(to right, #222224,#222224,#879dda,#e0e7f5)",
    	borderTopLeftRadius: 0,
    	borderTopRightRadius: 0,
    	height:50,
	}
}));

const MainHeader = ({ children }) => {
	const classes = useStyles();
	return <div className={classes.contactsHeader}>{children}</div>;
};
export default MainHeader;