import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

const App = () => {
	const [locale, setLocale] = useState();
  	const theme = createTheme({
      scrollbarStyles: {
			// Works on Firefox
			"&*": {
			  	scrollbarWidth: "thin",
			  	scrollbarColor: "#202fdf #d7d7d9",
			},
			// Works on Chrome, Edge, and Safari
			"&::-webkit-scrollbar": {
			  	width: 8,
          	height: 8,
			  	scrollbarColor: "#202fdf #d7d7d9",
			},
			"&::-webkit-scrollbar-track": {
			  	backgroundColor: "white"
			},
			"&::-webkit-scrollbar-thumb": {
			  	backgroundColor: "#202fdf",
			  	borderRadius: 50,
			  	border: "1px solid white",
			},
      },
      palette: {
      	primary: { main: "#2576d2" },
      },
   }, locale );

	useEffect(() => {
	   const i18nlocale = localStorage.getItem("i18nextLng");
	   const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);
	   if(browserLocale === "ptBR") {
	      setLocale(ptBR);
	   }
	}, []);

	return (
	   <ThemeProvider theme={theme}>
	   	<Routes />
	   </ThemeProvider>
	);
};
export default App;