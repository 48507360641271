/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo TICKETS
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import clsx from "clsx";
import { /*Card, Paper,*/ makeStyles } from "@material-ui/core";
import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";

const drawerWidth = 320;
const useStyles = makeStyles((theme) => ({
  	root: {
   	display: "flex",
    	height: "100%",
    	position: "relative",
    	overflow: "hidden",
  	},
  	ticketInfo: {
   	maxWidth: "50%",
    	flexBasis: "50%",
    	margin: -5,
    	[theme.breakpoints.down("sm")]: {
      	maxWidth: "80%",
      	flexBasis: "80%",
    	},
    	cursor:"pointer"
  	},
  	ticketActionButtons: {
   	maxWidth: "50%",
    	flexBasis: "50%",
    	display: "flex",
    	[theme.breakpoints.down("sm")]: {
      	maxWidth: "100%",
      	flexBasis: "100%",
      	padding: 2,
    	},
  	},
  	mainWrapper: {
   	flex: 1,
    	height: "100%",
    	display: "flex",
    	flexDirection: "column",
    	overflow: "hidden",
    	
    	borderTopLeftRadius: 0,
    	borderBottomLeftRadius: 0,
   	borderTopRightRadius: 15,
    	borderBottomRightRadius: 15,
    	borderLeft: "0px",
    	marginRight: -drawerWidth,
    	transition: theme.transitions.create("margin", {
      	easing: theme.transitions.easing.sharp,
      	duration: theme.transitions.duration.leavingScreen,
    	}),
  	},
  	mainWrapperShift: {
   	borderTopRightRadius: 15,
    	borderBottomRightRadius: 15,
    	transition: theme.transitions.create("margin", {
      	easing: theme.transitions.easing.easeOut,
      	duration: theme.transitions.duration.enteringScreen,
    	}),
    	marginRight: "0px",
  	},
}));

const Ticket = () => {
  	const { ticketId } = useParams();
  	const history = useHistory();
  	const classes = useStyles();

  	const [drawerOpen, setDrawerOpen] = useState(false);
  	const [loading, setLoading] = useState(true);
  	const [contact, setContact] = useState({});
  	const [ticket, setTicket]   = useState({});

  	useEffect(() => {
   	setLoading(true);
    	const delayDebounceFn = setTimeout(() => {
      	const fetchTicket = async () => {
        		try {
          		const { data } = await api.get("/tickets/" + ticketId);
          		setContact(data.contact);
          		setTicket(data);
          		setLoading(false);
        		} catch (err) {
          		setLoading(false);
          		toastError(err);
        		}
      	};
      	fetchTicket();
    	}, 500);
    	return () => clearTimeout(delayDebounceFn);
  	}, [ticketId, history]);

  	useEffect(() => {
   	const socket = openSocket();
    	socket.on("connect", () => socket.emit("joinChatBox", ticketId));
    	socket.on("ticket", (data) => {
      	if(data.action === "update") {
        		setTicket(data.ticket);
      	}
      	if(data.action === "delete") {
        		toast.success("Conversa(s) deletada(s) com sucesso.");
        		history.push("/tickets");
      	}
    	});

    	socket.on("contact", (data) => {
      	if(data.action === "update") {
        		setContact((prevState) => {
          		if(prevState.id === data.contact?.id) {
            		return { ...prevState, ...data.contact };
          		}
          		return prevState;
        		});
      	}
    	});

    	return () => {
      	socket.disconnect();
    	};
  	}, [ticketId, history]);

  	const handleDrawerOpen = () => {
   	setDrawerOpen(true);
  	};

  	const handleDrawerClose = () => {
   	setDrawerOpen(false);
  	};

  	return (
   	<div className={classes.root} id="drawer-container">
      	<div className={clsx(classes.mainWrapper, {[classes.mainWrapperShift]: drawerOpen})}>
        		{/* Menu HEADER Superior LEFT/RIGHT */}
        		<TicketHeader loading={loading}>
        			{/* Informações do TICKET */}
          		<div className={classes.ticketInfo}>
            		<TicketInfo	contact={contact} ticket={ticket} onClick={handleDrawerOpen} />
          		</div>
        			{/* Lista de BOTÕES */}
          		<div className={classes.ticketActionButtons}>
            		<TicketActionButtons ticket={ticket} />
          		</div>
        		</TicketHeader>
        		{/* Lista de Mensagens LEFT */}
        		<ReplyMessageProvider>
          		<MessagesList ticketId={ticketId} isGroup={ticket.isGroup}></MessagesList>
          		<MessageInput ticketStatus={ticket.status} />
        		</ReplyMessageProvider>
      	</div>
      	<ContactDrawer open={drawerOpen} handleDrawerClose={handleDrawerClose} contact={contact} loading={loading} />
    	</div>
  	);
};
export default Ticket;