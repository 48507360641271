/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2022
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo TICKET´S Header Principal
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React from "react";
import { useHistory } from "react-router-dom";
import { Card, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";
import ChevronLeftIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  	ticketHeader: {
   	display: "flex",
    	flex: "none",
    	borderLeft: 0,
    	backgroundColor: "#efefef",
    	borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
    	height: "62px",
    	[theme.breakpoints.down("sm")]: {
      	flexWrap: "wrap",
    		height: "auto",
    	},
		backgroundImage: "linear-gradient(to right, #fdfdfd,#fdfdfd,#fafafa,#fafafa)",
  	},
}));

const TicketHeader = ({ loading, children }) => {
  	const classes = useStyles();
  	const history = useHistory();
 	
	const handleBack = () => {
    	history.push("/tickets");
  	};

  	return (
   	<>
      	{loading ? (
        		<TicketHeaderSkeleton />
      	) : (
        		<Card square className={classes.ticketHeader}>
          		<IconButton style={{display:"none"}}
							onClick={e => {
								handleBack("");
							}}>
                     <ChevronLeftIcon />
               </IconButton>
          		{children}
        		</Card>
      	)}
    	</>
  	);
};
export default TicketHeader;