/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Modal Contacto
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	btnWrapper: {
		position: "relative",
		textTransform:"capitalize",
		fontFamily:"Verdana",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string().min(2, "Muito curto!").max(50, "Muito longo!").required("Required"),
	number: Yup.string().min(8, "Muito curto!").max(50, "Muito longo!"),
	email: Yup.string().email("E-mail inválido"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);

	const initialState = {
		name: "",
		number: "",
		email: "",
	};

	const [contact, setContact] = useState(initialState);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}
			if (!contactId) return;
			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				if (isMounted.current) {
					setContact(data);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleSaveContact = async values => {
		try {
			if (contactId) {
				await api.put(`/contacts/${contactId}`, values);
				handleClose();
			} else {
				const { data } = await api.post("/contacts", values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle style={{backgroundColor:"blue",color:"white"}} id="form-dialog-title">
		         <EditIcon style={{marginRight:2}} />
					{contactId ? `${i18n.t("contactModal.title.edit")}`:`${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}>
					
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("contactModal.form.mainInfo")}
								</Typography>
								<Field name="name" autoFocus variant="outlined" margin="dense"
									as={TextField}
									label={i18n.t("contactModal.form.name")}
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									className={classes.textField}
								/>
								<Field name="number" margin="dense" variant="outlined" placeholder="55(99)99999 9999"
									as={TextField}
									label={i18n.t("contactModal.form.number")}
									error={touched.number && Boolean(errors.number)}
									helperText={touched.number && errors.number}
								/>
								<div>
									<Field name="email" fullWidth margin="dense" variant="outlined" placeholder="Email address"
										as={TextField}
										label={i18n.t("contactModal.form.email")}
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
									/>
								</div>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1">
									{i18n.t("contactModal.form.extraInfo")}
								</Typography>

								<FieldArray name="extraInfo">
									{({ push, remove }) => (
										<>
											{values.extraInfo &&
												values.extraInfo.length > 0 &&
												values.extraInfo.map((info, index) => (
													<div className={classes.extraAttr} key={`${index}-info`}>
														<Field name={`extraInfo[${index}].name`} variant="outlined" margin="dense"
															as={TextField}
															label={i18n.t("contactModal.form.extraName")}
															className={classes.textField}
														/>
														<Field name={`extraInfo[${index}].value`} variant="outlined" margin="dense"
															as={TextField}
															label={i18n.t("contactModal.form.extraValue")}
															className={classes.textField}
														/>
														<IconButton size="small"
															onClick={() => remove(index)}>
															<DeleteOutlineIcon />
														</IconButton>
													</div>
												))}
											<div className={classes.extraAttr}>
												<Button style={{ flex: 1, marginTop: 8 }}
													variant="outlined"
													color="primary"
													onClick={() => push({ name: "", value: "" })}>
													{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray>
							</DialogContent>
							<DialogActions>
								<Button variant="outlined" color="secondary"	style={{fontFamily:"Verdana",textTransform:"capitalize"}}
									onClick={handleClose}
									disabled={isSubmitting}>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>
								<Button type="submit" color="primary" variant="contained"
									disabled={isSubmitting}
									className={classes.btnWrapper}>
									{contactId ? `${i18n.t("contactModal.buttons.okEdit")}`:`${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress size={24} className={classes.buttonProgress} />
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};
export default ContactModal;