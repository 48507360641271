	import React from "react";
	import Typography from "@material-ui/core/Typography";

	export default function Title(props) {
		return (
			<Typography style={{ color: "dodgerblue", fontFamily:"Arial"}} variant="h6" gutterBottom>
				{props.children}
			</Typography>
		);
	}
