	/* ==================================================================================================================================
   * Dashboard WhatsApp WEB - Gerenciador de mensagens
   * ==================================================================================================================================
   * Copyright (C) 2010 - 2022
   * Programador: Fabio P. vilas Boas 
   *
   *  - fabio.2705@hotmail.com
   *  - multi-clinicas@hotmail.com
   *  - multiclinicas.suporte@gmail.com
   *  - Skype: Multi Clinicas
   *
   * Modulo TICKET´S, Opções de MENU ( Transferir / Deletar )
   *
   * Projeto Original
   * https://github.com/canove/whaticket
   * Coded by WhatsApp WEB
   * ----------------------------------------------------------------------------------------------------------------------------------
   * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
	import React, { useContext, useEffect, useRef, useState } from "react";
	import MenuItem from "@material-ui/core/MenuItem";
	import Menu from "@material-ui/core/Menu";
	import { i18n } from "../../translate/i18n";
	import api from "../../services/api";
	import ConfirmationModal from "../ConfirmationModal";
	import TransferTicketModal from "../TransferTicketModal";
	import toastError from "../../errors/toastError";
	import { Can } from "../Can";
	import { AuthContext } from "../../context/Auth/AuthContext";
	
	import CallMissedIcon from "@material-ui/icons/CallMissed";
	import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
	
	const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
		const [confirmationOpen, setConfirmationOpen] = useState(false);
		const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
		const isMounted = useRef(true);
		const { user } = useContext(AuthContext);

		useEffect(() => {
			return () => {
				isMounted.current = false;
			};
		}, []);

		const handleDeleteTicket = async () => {
			try {
				await api.delete(`/tickets/${ticket.id}`);
			} catch (err) {
				toastError(err);
			}
		};

		const handleOpenConfirmationModal = e => {
			setConfirmationOpen(true);
			handleClose();
		};

		const handleOpenTransferModal = e => {
			setTransferTicketModalOpen(true);
			handleClose();
		};

		const handleCloseTransferTicketModal = () => {
			if (isMounted.current) {
				setTransferTicketModalOpen(false);
			}
		};

		return (
			<>
				<Menu
					id="menu-appbar"
					anchorEl={anchorEl}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={menuOpen}
					onClose={handleClose}>
					<MenuItem onClick={handleOpenTransferModal} style={{ color: "#2d51b0"}}>
		          	<CallMissedIcon style={{ marginRight: "2px"}} />
						{i18n.t("ticketOptionsMenu.transfer")}
					</MenuItem>
					<Can
						role={user.profile}
						perform="ticket-options:deleteTicket"
						yes={() => (
							<MenuItem onClick={handleOpenConfirmationModal} style={{ color: "#e30000"}}>
		          			<DeleteForeverIcon style={{ marginRight: "2px"}} />
								{i18n.t("ticketOptionsMenu.delete")}
							</MenuItem>
						)} />
				</Menu>
				<ConfirmationModal
					title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
						ticket.id
					} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
						ticket.contact.name
					}?`}
					open={confirmationOpen}
					onClose={setConfirmationOpen}
					onConfirm={handleDeleteTicket}>
					{i18n.t("ticketOptionsMenu.confirmationModal.message")}
				</ConfirmationModal>
				<TransferTicketModal
					modalOpen={transferTicketModalOpen}
					onClose={handleCloseTransferTicketModal}
					ticketid={ticket.id} />
			</>
		);
	};
	export default TicketOptionsMenu;