/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo TICKET´S Lista de Itens
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import { IconButton, Button, Tooltip } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

import ResolvedIcon from '@material-ui/icons/CheckCircle';
//import { Visibility } from '@material-ui/icons';

import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
// API
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
		padding: "0 5 0 0",
		marginLeft:0,
		backgroundColor:"inherit",
		cursor:"pointer",
	},
	pendingTicket: {
		cursor: "unset",
	},
	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 45,
		marginLeft: "auto",
		fontFamily: "Tahoma",
		fontSize: "10px",
	},
	defaultButtom: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 26,
		marginLeft: "auto",
		fontWeight: "600",
		fontFamily: "Arial",
		fontSize: "12px",
		textTransform:"capitalize",
		padding: "1px 15px",
		borderRadius: 25,
	},
	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
		marginRight:7,
	},
	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
		marginLeft: "3px",
		fontFamily: "Verdana",
		fontSize: "14px",
		color: "#797a86",
		cursor:"pointer",
	},
	ticketQueueColor: {
		flex: "none",
		width: "10px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
		cursor: "pointer",
	},
	lastMessageTime: {
		justifySelf: "flex-end",
		fontFamily: "Tahoma",
		fontSize: "12px",
		color: "#3f6ca9",
		float: "right",
	},
	contactLastMessage: {
		paddingRight: 20,
		fontStyle: "normal",
		fontSize: "13px",
	},
	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},
	ackIcons: {
 		color: "#787887",
 		fontSize: "1rem",
 		marginRight: 2,
 		verticalAlign: "middle",
	},
	ackDoneAllIcon: {
 		color: "#2b8e32",
 		fontSize: "1rem",
 		marginRight: 2,
 		verticalAlign: "middle",
	},
	// Alterações
	acceptButton: {
		position: "absolute",
		left: "50%",
	},
	userTag: {
		position: "absolute",
		marginRight: 5,
		right: 5,
		bottom: 5,
		background: "#2576D2",
		color: "#ffffff",
		border: "1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},
	imgAvatar: {
		width:"42px",
		height:"42px",
		boxShadow:"0 1px 1px 1px #eeeeee",
		border:"1px solid #ffffff",
	},
	messageSetor: {
		alignSelf: "center",
		marginRight: 10,
		marginLeft: "auto",
		padding:"1px 12px",
		boxShadow:"1px 1px 1px 0px #ffffff",
		fontFamily:"Arial",
		fontSize:10,
		borderRadius:25,
	},
	bottomButton: {
		position: "relative",
		//marginRight:8,
		//left: "25%",
		padding: 2,
	},
	containerVisibility: {
		alignSelf: "center",
		marginRight:0,
		//left: "25%",
		marginLeft: "auto",
	},
}));

const TicketListItem = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	},[]);
	// Seleciona o Ticket
	const handleAcepptTicket = async(id) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if(isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};
	// Visualiza o Ticket
	/*const handleViewTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "pending",
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};*/

	return (
		<React.Fragment key={ticket.id}>
			<ListItem dense button
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}
				onClick={e => { handleAcepptTicket(ticket.id) }}>
	      	{/* VIEW IMG Paciente */}
				<ListItemAvatar>
					<Tooltip arrow placement="right" title={ticket.queue?.name || "Sem Setor"}>
						<Avatar src={ticket?.contact?.profilePicUrl} className={classes.imgAvatar} />
					</Tooltip>
				</ListItemAvatar>

				<Tooltip arrow title={<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper> || "Sem mensagem"}>
					<ListItemText  
						primary={
							<span className={classes.contactNameWrapper}>
		      				{/* VIEW Name Painel */}
								<Typography noWrap component="span" variant="body2" style={{color:"#2e4f9a",fontSize:"1em"}}>
									{ticket.contact.name}
								</Typography>

								{ticket.status === "closed" && (
									<Tooltip title={"Mensagens Resolvidas"}>
										<Button variant="contained" color="primary" 
											className={classes.defaultButtom} startIcon={<ResolvedIcon style={{fontSize:"1.3em"}} />}>
											{"Resolvido"}
										</Button>
									</Tooltip>
								)}
	      					{/* VIEW COR Setor */}
								<div className={classes.messageSetor} style={{borderColor:ticket.queue?.color,color:"#ffffff",backgroundColor:ticket.queue?.color || "#7C7C7C"}}>{ticket.queue?.name || "Sem Setor"}</div>
		      				{/* VIEW DT HS Painel */}
								{ticket.lastMessage && (
									<Typography	className={classes.lastMessageTime} component="span" variant="body2">
										{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
											<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
										) : (
											<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
										)}
									</Typography>
								)}
							</span>
						}
						secondary={
							<span className={classes.contactNameWrapper}>
								<Typography className={classes.contactLastMessage} noWrap component="span" variant="body2">
	                    		{/*** Status da Mensagem ***/}
									{ticket.lastMessage ? (
										<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
									) : (
										<br />
									)}
								</Typography>
	      					{/* BUTTOM Visible
								<div className={classes.containerVisibility}>
									<IconButton
										className={classes.bottomButton}
										color="primary"
										onClick={e => handleViewTicket(ticket.id)} >
										<Visibility />
									</IconButton>
								</div>
								 */}
								<Badge overlap="rectangular" className={classes.newMessagesCount} badgeContent={ticket.unreadMessages} classes={{badge:classes.badgeStyle}} />
							</span>
						}
					/>
				</Tooltip>
				{ticket.status === "pending" && (
					<ButtonWithSpinner style={{display:"none"}} size="small" color="primary" variant="contained"
						className={classes.acceptButton}
						loading={loading}
						onClick={e => handleAcepptTicket(ticket.id)}>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>
				)}
			</ListItem>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};
export default TicketListItem;