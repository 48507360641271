	/* ==================================================================================================================================
   * Dashboard WhatsApp WEB - Gerenciador de mensagens
   * ==================================================================================================================================
   * Copyright (C) 2010 - 2023
   * Programador: Fabio P. vilas Boas 
   *
   *  - fabio.2705@hotmail.com
   *  - multi-clinicas@hotmail.com
   *  - multiclinicas.suporte@gmail.com
   *  - Skype: Multi Clinicas
   *
   * Modulo API
   *
   * Projeto Original
   * https://github.com/canove/whaticket
   * Coded by Multi Clinicas APP
   * ----------------------------------------------------------------------------------------------------------------------------------
   * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
	import axios from "axios";
	import { getBackendUrl } from "../config";

	const api = axios.create({
		baseURL: getBackendUrl(), withCredentials: true,
	});
	export default api;