import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, Drawer, Tooltip, List, Divider, MenuItem, IconButton, Menu } from "@material-ui/core";

import ChevronLeftIcon from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";

import AccountBox from "@material-ui/icons/AssignmentInd";
import ExitToApp from "@material-ui/icons/ExitToApp";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import NewTicketModal from "../components/NewTicketModal";
import { i18n } from "../translate/i18n";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  	root: {
   	display: "flex",
    	height: `calc(100vh - 0px)`,
  	},
  	toolbarList: {
    	margin: theme.spacing(0.8),
   	display: "flex",
    	alignItems: "center",
		justifyContent: "flex-start",
	},
  	toolbarIcon: {
  		paddingTop: "12px",
  		marginLeft: theme.spacing(2.2),
   	display: "flex",
    	alignItems: "center",
    	justifyContent: "flex-start",
    	minHeight: "48px",
  	},
  	appBar: {
   	zIndex: theme.zIndex.drawer + 1,
    	transition: theme.transitions.create(["width","margin"], {
      	easing: theme.transitions.easing.sharp,
      	duration: theme.transitions.duration.leavingScreen,
    	}),
  	},
  	appBarShift: {
   	marginLeft: drawerWidth,
    	width: `calc(100% - ${drawerWidth}px)`,
    	transition: theme.transitions.create(["width","margin"], {
      	easing: theme.transitions.easing.sharp,
      	duration: theme.transitions.duration.enteringScreen,
    	}),
  	},
  	drawerPaper: {
   	position: "relative",
    	whiteSpace: "nowrap",
    	width: drawerWidth,
    	transition: theme.transitions.create("width", {
      	easing: theme.transitions.easing.sharp,
      	duration: theme.transitions.duration.enteringScreen,
    	}),
		backgroundImage: "linear-gradient(to top, #161618,#36363a,#55555b,#161618)",
		border: 0,
  	},
  	drawerPaperClose: {
   	overflowX: "hidden",
    	transition: theme.transitions.create("width", {
      	easing: theme.transitions.easing.sharp,
      	duration: theme.transitions.duration.leavingScreen,
    	}),
    	width: theme.spacing(7),
    	[theme.breakpoints.up("sm")]: {
      	width: theme.spacing(9),
    	},
  	},
  	content: {
   	flex: 1,
    	overflow: "hidden",
  	},
  	container: {
   	paddingTop: theme.spacing(4),
    	paddingBottom: theme.spacing(4),
  	},
  	paper: {
   	padding: theme.spacing(2),
    	display: "flex",
    	overflow: "auto",
    	flexDirection: "column",
  	},
}));

const LoggedInLayout = ({ children }) => {
	const classes = useStyles();
  	const [userModalOpen, setUserModalOpen] = useState(false);
  	const [anchorEl, setAnchorEl] = useState(null);
  	const [menuOpen, setMenuOpen] = useState(false);
  	const { handleLogout, loading } = useContext(AuthContext);
  	const [drawerOpen, setDrawerOpen] = useState(false);
  	const [drawerVariant, setDrawerVariant] = useState("permanent");
  	const { user } = useContext(AuthContext);
  	// Personalizado
  	const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
	
	// Open / Close (Painel)
  	useEffect(() => {
   	if(document.body.offsetWidth > 600) {
      	setDrawerOpen(false);
    	}
  	}, []);
  	useEffect(() => {
   	if(document.body.offsetWidth < 600) {
      	setDrawerVariant("temporary");
    	} else {
      	setDrawerVariant("permanent");
    	}
  	}, [drawerOpen]);
  	const handleMenu = (event) => {
   	setAnchorEl(event.currentTarget);
    	setMenuOpen(true);
  	};
  	const handleCloseMenu = () => {
   	setAnchorEl(null);
    	setMenuOpen(false);
  	};
  	const handleOpenUserModal = () => {
   	setUserModalOpen(true);
    	handleCloseMenu();
  	};
  	const handleClickLogout = () => {
   	handleCloseMenu();
    	handleLogout();
  	};
  	const drawerClose = () => {
   	if(document.body.offsetWidth < 600) {
      	setDrawerOpen(false);
		}
  	};
  	if(loading) {
    	return <BackdropLoading />;
  	}

  	return (
   	<div className={classes.root}>
      	<Drawer variant={drawerVariant} className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        		classes={{
          		paper: clsx(
            		classes.drawerPaper, !drawerOpen && classes.drawerPaperClose
          		),
        		}}
        		open={drawerOpen}>
        		<div className={classes.toolbarIcon}>
          		<IconButton size="small" variant="outlined"
          			onClick={() => setDrawerOpen(!drawerOpen)}>
            		<ChevronLeftIcon style={{color:"white"}} />
          		</IconButton>
        			<div style={{marginLeft:30,color:"white"}}>WhatsApp 2023</div>
        		</div>
        		<Divider />
        		<List className={classes.toolbarList}>
          		<MainListItems drawerClose={drawerClose} />
        		</List>
	         {/* Usuario */}
    			<Tooltip arrow title={"Perfil do Usuário"}>
            	<IconButton aria-haspopup="true" aria-controls="menu-appbar" aria-label="account of current user"
              		onClick={handleMenu}>
              		<AccountCircle style={{color:"#d5cc24",fontSize:"1.2em"}} />
            	</IconButton>
				</Tooltip>
         	<Menu id="menu-appbar" anchorEl={anchorEl} getContentAnchorEl={null}
           		open={menuOpen}
           		anchorOrigin={{vertical: "bottom", horizontal: "right"}}
           		transformOrigin={{vertical: "top", horizontal: "right"}}
           		onClose={handleCloseMenu}>
    				
    				<Tooltip arrow title={"Editar perfil do Usuário"}>
	           		<MenuItem onClick={handleOpenUserModal}>
	           			<AccountBox style={{ color: "dodgerblue", fontSize: "1.2em" }} />
	             		{i18n.t("mainDrawer.appBar.user.profile")}
	           		</MenuItem>
					</Tooltip>
    				<Tooltip arrow title={"Fazer Login"}>
	           		<MenuItem onClick={handleClickLogout}>
	           			<ExitToApp style={{ color: "firebrick ", fontSize: "1.2em" }} />
	             		{i18n.t("mainDrawer.appBar.user.logout")}
	           		</MenuItem>
					</Tooltip>
         	</Menu>
	         {/* Noticações das mensagens recebidas */}
        		<div style={{display:"none",textAlign:"center"}}>
        			<Divider />
          		{user.id && <NotificationsPopOver />}
          	</div>
      	</Drawer>
	      
	      {/* Novos Tickets */}
      	<NewTicketModal modalOpen={newTicketModalOpen}
      		onClose={(e) => setNewTicketModalOpen(false)}
      	/>
	      {/* EDITAR Perfil do Usuario */}
      	<UserModal open={userModalOpen}
      		onClose={() => setUserModalOpen(false)} userId={user?.id}
      	/>
      	<main className={classes.content}>
        		{children ? children : null}
      	</main>
    	</div>
  	);
};
export default LoggedInLayout;