/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Conexões
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Avatar, Button, TableBody, TableRow, TableCell, IconButton, Table, TableHead, Paper, Tooltip,	Typography,	CircularProgress } from "@material-ui/core";
import {	Edit,	CheckCircle, SignalCellularConnectedNoInternet2Bar,	SignalCellularConnectedNoInternet0Bar,	SignalCellular4Bar, BatteryChargingFull, CropFree, DeleteOutline } from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";

import SyncAltIcon from "@material-ui/icons/Dialpad";
import AddIcon from "@material-ui/icons/AddOutlined";
import SettingsIcon from "@material-ui/icons/CellWifi";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// Limitar Conexões
import { getConnectionsLimit } from "../../config";
import ImgLogomarca from "../../assets/logomarca.png";

const useStyles = makeStyles(theme => ({
	mainPaper: {
   	display: 1,
    	height: "calc(100vh - 56px)",
 		padding: theme.spacing(0.2),
 		overflowY: "auto",
 		...theme.scrollbarStyles,
 		background: "#fafafa",
 		fontWeight: "normal",
	},
  	table: {
		fontFamily:"Verdana",
    	"& .MuiTableCell-root": {
      	border:"1px solid rgba(224, 224, 224, 1)",
    	},
 		fontWeight: "normal",
  	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
      borderRadius: "0px",
	},
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(15),
		border: "1px solid #dadde9",
	},
	tooltipPopper: {
		textAlign: "center",
	},
	buttonProgress: {
		color: green[500],
	},
	textLink: {
		display:"flex-end",
		marginLeft:"6px",
		fontSize:"18px",
		color:"#2d42d2",
		fontWeight:"normal",
	},
	divImgIconLinkCircle: {
 		padding: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	  	width: 150,
	  	height: 120,
	  	border:"1px solid #dfdfdf",
	  	boxSizing:"border-box",
	  	borderRadius:"50%",
	  	marginBottom: 20,
    	boxShadow: "1px 1px 1px #ededed",
	},
	imgIconLinkCircle: {
	  	objectFit:"contain",
	  	height:"100%",
	  	width:"100%",
    	border: "0px",
	},
}));

const CustomToolTip = ({ title, content, children }) => {
	const classes = useStyles();
	return (
		<Tooltip arrow
			classes={{
				tooltip: classes.tooltip,
				popper: classes.tooltipPopper,
			}}
			title={
				<React.Fragment>
					<Typography gutterBottom color="inherit">
						{title}
					</Typography>
					{content && <Typography>{content}</Typography>}
				</React.Fragment>
			}>
			{children}
		</Tooltip>
	);
};

const Connections = () => {
	const classes = useStyles();
	const { whatsApps, loading } = useContext(WhatsAppsContext);
	const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		whatsAppId: "",
		open: false,
	};
	const [confirmModalInfo, setConfirmModalInfo] = useState(
		confirmationModalInitialState
	);
	const handleStartWhatsAppSession = async(whatsAppId) => {
		try {
			await api.post(`/whatsappsession/${whatsAppId}`);
		} catch (err) {
			toastError(err);
		}
	};
	const handleRequestNewQrCode = async(whatsAppId) => {
		try {
			await api.put(`/whatsappsession/${whatsAppId}`);
		} catch (err) {
			toastError(err);
		}
	};
	const handleOpenWhatsAppModal = () => {
		setSelectedWhatsApp(null);
		setWhatsAppModalOpen(true);
	};
	const handleCloseWhatsAppModal = useCallback(() => {
		setWhatsAppModalOpen(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setWhatsAppModalOpen]);
	const handleOpenQrModal = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setQrModalOpen(true);
	};
	const handleCloseQrModal = useCallback(() => {
		setSelectedWhatsApp(null);
		setQrModalOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);
	const handleEditWhatsApp = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setWhatsAppModalOpen(true);
	};
	const handleOpenConfirmationModal = (action, whatsAppId) => {
		if(action === "disconnect") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.disconnectTitle"),
				message: i18n.t("connections.confirmationModal.disconnectMessage"),
				whatsAppId: whatsAppId,
			});
		}
		if(action === "delete") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.deleteTitle"),
				message: i18n.t("connections.confirmationModal.deleteMessage"),
				whatsAppId: whatsAppId,
			});
		}
		setConfirmModalOpen(true);
	};

	const handleSubmitConfirmationModal = async () => {
		if(confirmModalInfo.action === "disconnect") {
			try {
				await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
			} catch (err) {
				toastError(err);
			}
		}
		if(confirmModalInfo.action === "delete") {
			try {
				await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
				toast.success(i18n.t("connections.toasts.deleted"));
			} catch (err) {
				toastError(err);
			}
		}
		setConfirmModalInfo(confirmationModalInitialState);
	};
	
	const renderActionButtons = whatsApp => {
		return (
			<>
				{whatsApp.status === "qrcode" && (
					<Button size="small" variant="contained" color="primary"
						onClick={() => handleOpenQrModal(whatsApp)}>
						{i18n.t("connections.buttons.qrcode")}
					</Button>
				)}
				{whatsApp.status === "DISCONNECTED" && (
					<>
						<Button size="small" variant="outlined" color="primary"
							onClick={() => handleStartWhatsAppSession(whatsApp.id)}>
          				<AddIcon style={{ color: "primary", marginRight: "2px"}} />
							{i18n.t("connections.buttons.tryAgain")}
						</Button>
						{" "}
						<Button size="small" variant="outlined" color="secondary"
							onClick={() => handleRequestNewQrCode(whatsApp.id)}>
          				<AddIcon style={{color:"primary",marginRight:"2px"}} />
							{i18n.t("connections.buttons.newQr")}
						</Button>
					</>
				)}
				
				{(whatsApp.status === "CONNECTED" || whatsApp.status === "PAIRING" || whatsApp.status === "TIMEOUT") && (
					<div>
						<div className={classes.divImgIconLinkCircle}>
	            		<Avatar src={ImgLogomarca} className={classes.imgIconLinkCircle} />
						</div>
						<CustomToolTip title={i18n.t("WhatsApp está conectado!")}>
							<Button style={{padding:"5px 25px 5px 25px",textTransform:'capitalize',fontSize:"16px",borderRadius:"25px"}}
								size="medium" variant="outlined" color="primary"
								onClick={() => {
									handleOpenConfirmationModal("disconnect", whatsApp.id);
								}}>
		          			<WhatsAppIcon style={{fontSize:"1.4em", marginRight: "2px"}} />
								{i18n.t("Reconectar")}
							</Button>
						</CustomToolTip>
					</div>
				)}
				{whatsApp.status === "OPENING" && (
					<Button size="small" variant="outlined" disabled color="default">
						{i18n.t("connections.buttons.connecting")}
					</Button>
				)}
			</>
		);
	};

	const renderStatusToolTips = whatsApp => {
		return (
			<div className={classes.customTableCell}>
				{whatsApp.status === "DISCONNECTED" && (
					<CustomToolTip
						title = {i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}>
						<SignalCellularConnectedNoInternet0Bar color="secondary" />
					</CustomToolTip>
				)}
				{whatsApp.status === "OPENING" && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
				{whatsApp.status === "qrcode" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.qrcode.title")}
						content={i18n.t("connections.toolTips.qrcode.content")}>
						<CropFree />
					</CustomToolTip>
				)}
				{whatsApp.status === "CONNECTED" && (
					<CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
						<SignalCellular4Bar style={{ color: green[500] }} />
					</CustomToolTip>
				)}
				{(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.timeout.title")}
						content={i18n.t("connections.toolTips.timeout.content")}>
						<SignalCellularConnectedNoInternet2Bar color="secondary" />
					</CustomToolTip>
				)}
			</div>
		);
	};

	const renderStatusBattery = whatsApp => {
		// primary secondary
		return (
			<div className={classes.customTableCell}>
				{whatsApp.battery < 20 && (
					<CustomToolTip title={`${whatsApp.battery}`}>
						<BatteryChargingFull color="primary" />
					</CustomToolTip>
				)}
				{whatsApp.battery > 20 && (
					<CustomToolTip title={`${whatsApp.battery}`}>
						<BatteryChargingFull style={{ color: green[500] }} />
					</CustomToolTip>
				)}
			</div>
		);
	};
	// console.log(whatsApps);
	return (
		<MainContainer>
			<ConfirmationModal title={confirmModalInfo.title}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={handleSubmitConfirmationModal}>
				{confirmModalInfo.message}
			</ConfirmationModal>
			<QrcodeModal
				open={qrModalOpen}
				onClose={handleCloseQrModal}
				whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
			/>
			<WhatsAppModal
				open={whatsAppModalOpen}
				onClose={handleCloseWhatsAppModal}
				whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
			/>
			<MainHeader>
	        	<SyncAltIcon style={{padding:2,marginLeft:8,color:"white",fontSize:"2.2em",marginTop:"-5px",marginRight:"5px"}} />
	        	<Title style={{fontFamily:"Tahoma",fontWeight:"normal"}}>
	         	<span style={{fontSize:"0.8em",color:"white"}}>{i18n.t("connections.title")}</span>
	        	</Title>
				<MainHeaderButtonsWrapper>
       			<Button variant="contained" color="primary" size="small"
		         	style={{
		         		padding: "5px 25px 5px 25px",
		         		textTransform:'capitalize',
		         		fontFamily:"Tahoma",
		         		fontSize:"15px",
		         		borderRadius:"5px",
		         		color: "white"
		         	}}
       				disabled = {whatsApps?.length >= getConnectionsLimit() ?? ""} 
						onClick={handleOpenWhatsAppModal}>
          			<SettingsIcon style={{fontSize:"1.2em",color:"primary",marginRight:"2px"}} />
						{i18n.t("Configurar Conexão")}
       			</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>
			
			<Paper className={classes.mainPaper} variant="outlined">
				<Table size="medium">
       			<TableHead style={{background:"#fafafa"}}>
						<TableRow>
							<TableCell align="center">
								{i18n.t("Nome da Conexão")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("Nível Battery")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("connections.table.status")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("connections.table.session")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("connections.table.lastUpdate")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("connections.table.default")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("connections.table.actions")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody style={{background:"#f1f1f5",color:"#fff"}}>
						{loading ? (
							<TableRowSkeleton />
						) : (
							<>
							{whatsApps?.length > 0 &&
								whatsApps.map(whatsApp => (
									<TableRow key={whatsApp.id}>
										<TableCell align="center">
						          		<span className={classes.textLink}>
												{whatsApp.name}
						          		</span>
										</TableCell>
										<TableCell align="center">
											{renderStatusBattery(whatsApp)}
										</TableCell>
										<TableCell align="center">
											{renderStatusToolTips(whatsApp)}
										</TableCell>
										<TableCell align="center">
											<div align="center">{renderActionButtons(whatsApp)}</div>
										</TableCell>
										<TableCell align="center" style={{fontSize:"1.2em",color:"#3c3cc4"}}>
											{format(parseISO(whatsApp.updatedAt), "dd/MM/yy :: HH:mm")}
										</TableCell>
										<TableCell align="center">
											{whatsApp.isDefault && (
												<div className={classes.customTableCell}>
													<CheckCircle style={{ color: green[500] }} />
												</div>
											)}
										</TableCell>
										<TableCell align="center">
       									<Tooltip arrow title={"Editar Conexão"}>
												<IconButton size="medium" variant="outlined" color="primary"
													onClick={() => handleEditWhatsApp(whatsApp)}>
													<Edit />
												</IconButton>
											</Tooltip>
       									<Tooltip arrow title={"Excluir Conexão"}>
												<IconButton size="medium" variant="outlined" color="secondary"
													onClick={e => {
														handleOpenConfirmationModal("delete", whatsApp.id);
													}}>
													<DeleteOutline />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								)
							)}
							</>
						)}
					</TableBody>
				</Table>
			</Paper>
		</MainContainer>
	);
};
export default Connections;